import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardActionButton from "views/components/DashboardActionButton";
import dataTableData from "./data/dataTableData";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { showMsg } from "utils/general";
import "./ClientConfig.css";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import { useCookies } from "react-cookie";
import BlockedDomainManagementTable from "./components/BlockedDomainsTable/ManagementTable";
import CreateNewBlockedDomainDialog from "./components/BlockedDomainsTable/createNewDialog";
import EditBlockedDomainDialog from "./components/BlockedDomainsTable/EditDialog";
import MDManagementTable from "./components/AllowedMDTable/ManagementTable";
import CreateNewMDDialog from "./components/AllowedMDTable/createNewDialog";
import EditMDDialog from "./components/AllowedMDTable/EditDialog";

import {
  getBlockedDomains,
  deleteBlockedDomain,
  getMDDomains,
  deleteMDDomain,
} from "features/company/superAdminSiteSecurityActions";
import { displayExceptionErrorMessage } from "utils/general";

const Index = (props) => {
  const { objUser } = props;
  const [cookies] = useCookies(["isAuthenticated"]);

  /**Blocked domains*/
  const [isLoadingBlockedDomains, setIsLoadingBlockedDomains] = useState(false);
  const [isDeletingBlockedDomain, setIsDeletingBlockedDomain] = useState(false);
  const [objBlockedDomainTableData, setObjBlockedDomainTableData] =
    useState(dataTableData);
  const [totalBlockedDomainCount, setTotalBlockedDomainCount] = useState(0);
  const [openNewBlockedDomainDialog, setOpenNewBlockedDomainDialog] =
    useState(false);
  const [openEditBlockedDomainDialog, setOpenEditBlockedDomainDialog] =
    useState(false);
  const [editBlockedDomainDialogData, setEditBlockedDomainDialogData] =
    useState({});
  const [perPageBD, setPerPageBD] = useState(10);
  const [pageBD, setPageBD] = useState(1);

  /**Allowed MD */
  const [isLoadingMDDomains, setIsLoadingMDDomains] = useState(false);
  const [isDeletingMDDomain, setIsDeletingMDDomain] = useState(false);
  const [objMDDomainTableData, setObjMDDomainTableData] =
    useState(dataTableData);
  const [totalMDDomainCount, setTotalMDDomainCount] = useState(0);
  const [openNewMDDomainDialog, setOpenNewMDDomainDialog] = useState(false);
  const [openEditMDDomainDialog, setOpenEditMDDomainDialog] = useState(false);
  const [editMDDomainDialogData, setEditMDDomainDialogData] = useState({});
  const [perPageMD, setPerPageMD] = useState(10);
  const [pageMD, setPageMD] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  /**Blocked domains */
  const btnOnClickNewBD = () => {
    setOpenNewBlockedDomainDialog(true);
  };

  const onCloseNewBlockedDomainDialog = () => {
    setOpenNewBlockedDomainDialog(false);
  };

  const onSubmitNewBlockedDomainDialog = (response) => {
    populateBlockedDomainTableData(response);
    setOpenNewBlockedDomainDialog(false);
  };

  const editBD = (dataFromActionBtn) => {
    setEditBlockedDomainDialogData(dataFromActionBtn);
    setOpenEditBlockedDomainDialog(true);
  };

  const onCloseEditBlockedDomainDialog = () => {
    setOpenEditBlockedDomainDialog(false);
  };

  const onSubmitEditBlockedDomainDialog = (response) => {
    populateBlockedDomainTableData(response);
    setOpenEditBlockedDomainDialog(false);
  };

  const loadMoreBD = (_per_page, _page) => {
    setIsLoadingBlockedDomains(true);
    dispatch(getBlockedDomains({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingBlockedDomains(false);
        populateBlockedDomainTableData(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingBlockedDomains(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const populateBlockedDomainTableData = (response) => {
    const objRows = [];
    const objBDList = response.data.blocked_domains;
    for (let index = 0; index < objBDList.length; index++) {
      const element = objBDList[index];
      const objTr = {};
      objTr.domain = element.domain;
      objTr.id = element.id;
      objRows.push(objTr);
    }
    setTotalBlockedDomainCount(response.data.total);
    setObjBlockedDomainTableData({
      ...dataTableData,
      rows: objRows,
    });
  };

  const deleteBD = async (dataFromActionBtn) => {
    setIsDeletingBlockedDomain(true);

    dispatch(deleteBlockedDomain({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingBlockedDomain(false);

        const response = originalPromiseResult;
        if (response.status === 200 || response.status === 201) {
          showMsg("success", "Domain deleted");
          populateBlockedDomainTableData(response);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsDeletingBlockedDomain(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  /*** allowed multiple MSPs*/
  const btnOnClickNewMD = () => {
    setOpenNewMDDomainDialog(true);
  };

  const onCloseNewMDDomainDialog = () => {
    setOpenNewMDDomainDialog(false);
  };

  const onSubmitNewMDDomainDialog = (response) => {
    populateMDDomainTableData(response);
    setOpenNewMDDomainDialog(false);
  };

  const editMD = (dataFromActionBtn) => {
    setEditMDDomainDialogData(dataFromActionBtn);
    setOpenEditMDDomainDialog(true);
  };

  const onCloseEditMDDomainDialog = () => {
    setOpenEditMDDomainDialog(false);
  };

  const onSubmitEditMDDomainDialog = (response) => {
    populateMDDomainTableData(response);
    setOpenEditMDDomainDialog(false);
  };

  const loadMoreMD = (_per_page, _page) => {
    setIsLoadingMDDomains(true);
    dispatch(getMDDomains({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMDDomains(false);
        populateMDDomainTableData(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMDDomains(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const populateMDDomainTableData = (response) => {
    const objRows = [];
    const objList = response.data.allowed_md_domains;
    for (let index = 0; index < objList.length; index++) {
      const element = objList[index];
      const objTr = {};
      objTr.domain = element.domain;
      objTr.id = element.id;
      objRows.push(objTr);
    }
    setTotalMDDomainCount(response.data.total);
    setObjMDDomainTableData({
      ...dataTableData,
      rows: objRows,
    });
  };

  const deleteMD = async (dataFromActionBtn) => {
    setIsDeletingMDDomain(true);
    dispatch(deleteMDDomain({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingMDDomain(false);

        const response = originalPromiseResult;
        if (response.status === 200 || response.status === 201) {
          showMsg("success", "Domain deleted");
          populateMDDomainTableData(response);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsDeletingMDDomain(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {
    if (objUser.user.is_super_admin !== 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    loadMoreBD(perPageBD, pageBD);
    loadMoreMD(perPageMD, pageMD);
    //fetchTemplates();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* Blocked domains CRUD dialgos */}
      <CreateNewBlockedDomainDialog
        open={openNewBlockedDomainDialog}
        onClose={onCloseNewBlockedDomainDialog}
        onSubmit={onSubmitNewBlockedDomainDialog}
        objUser={props.objUser}
      />
      <EditBlockedDomainDialog
        open={openEditBlockedDomainDialog}
        onClose={onCloseEditBlockedDomainDialog}
        onSubmit={onSubmitEditBlockedDomainDialog}
        objUser={props.objUser}
        editDialogData={editBlockedDomainDialogData}
      />

      {/* Allowed multiple domains CRUD dialogs */}
      <CreateNewMDDialog
        open={openNewMDDomainDialog}
        onClose={onCloseNewMDDomainDialog}
        onSubmit={onSubmitNewMDDomainDialog}
        objUser={props.objUser}
      />
      <EditMDDialog
        open={openEditMDDomainDialog}
        onClose={onCloseEditMDDomainDialog}
        onSubmit={onSubmitEditMDDomainDialog}
        objUser={props.objUser}
        editDialogData={editMDDomainDialogData}
      />
      <MDBox>
        <Grid
          spacing={2}
          container
          width="100%"
          sx={{ alignItems: "flex-start" }}
        >
          {/* bd */}
          <Grid item xs={12} lg={6}>
            <Fragment>
              <DashboardActionButton
                action={btnOnClickNewBD}
                btnText="Add Domain"
                btnIcon="add"
                textColor="white"
                bgColor="success"
              />
            </Fragment>
            <Card sx={{ marginTop: "16px" }}>
              <MDBox p={2}>
                <MDTypography variant="h5" fontWeight="bold">
                  Blocked Domains
                </MDTypography>
              </MDBox>

              {isLoadingBlockedDomains === false &&
              objBlockedDomainTableData.rows.length > 0 &&
              totalBlockedDomainCount > 0 &&
              isDeletingBlockedDomain === false ? (
                <BlockedDomainManagementTable
                  table={objBlockedDomainTableData}
                  entriesPerPage={true}
                  canSearch
                  totalCount={totalBlockedDomainCount}
                  loadMoreObj={loadMoreBD}
                  perPage={perPageBD}
                  setPerPage={setPerPageBD}
                  _page={pageBD}
                  setPage={setPageBD}
                  setObjBlockedDomainTableData={setObjBlockedDomainTableData}
                  deleteObj={deleteBD}
                  editObj={editBD}
                />
              ) : (
                <Fragment>
                  {isLoadingBlockedDomains ||
                  isDeletingBlockedDomain === true ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p style={{ padding: "1rem" }}>
                      No blocked domains added yet
                    </p>
                  )}
                </Fragment>
              )}
            </Card>
          </Grid>

          {/* md */}
          <Grid item xs={12} lg={6}>
            <Fragment>
              <DashboardActionButton
                action={btnOnClickNewMD}
                btnText="Add Domain"
                btnIcon="add"
                textColor="white"
                bgColor="success"
              />
            </Fragment>
            <Card sx={{ marginTop: "16px" }}>
              <MDBox p={2}>
                <MDTypography variant="h5" fontWeight="bold">
                  Allowed To Have Multiple Signups
                </MDTypography>
              </MDBox>

              {isLoadingMDDomains === false &&
              objMDDomainTableData.rows.length > 0 &&
              totalMDDomainCount > 0 &&
              isDeletingMDDomain === false ? (
                <MDManagementTable
                  table={objMDDomainTableData}
                  entriesPerPage={true}
                  canSearch
                  totalCount={totalMDDomainCount}
                  loadMoreObj={loadMoreMD}
                  perPage={perPageMD}
                  setPerPage={setPerPageMD}
                  _page={pageMD}
                  setPage={setPageMD}
                  setObjTableData={setObjMDDomainTableData}
                  deleteObj={deleteMD}
                  editObj={editMD}
                />
              ) : (
                <Fragment>
                  {isLoadingMDDomains || isDeletingMDDomain === true ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p style={{ padding: "1rem" }}>No domains added yet</p>
                  )}
                </Fragment>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Index;

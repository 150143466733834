/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import DefaultCell from "views/components/TableCells/DefaultCell";

const UsersManagementListCardDataTableData = {
  columns: [
    {
      Header: "first name",
      accessor: "user",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "last name",
      accessor: "lastName",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "role",
      accessor: "role",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default UsersManagementListCardDataTableData;

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { COMPANIES_SERVER } from "components/utils/constants/misc";

/**company template actions */
export const getAssessmentTemplates = createAsyncThunk(
  "assessmentTemplate/get",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/templates`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteAssessmentTemplate = createAsyncThunk(
  "assessmentTemplate/delete",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId } = values;
      const res = await axios.delete(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/templates/${templateId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createNewTemplateClone = createAsyncThunk(
  "assessmentTemplate/createNewTemplateClone",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/templates/clone`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createNewTemplateBulk = createAsyncThunk(
  "assessmentTemplate/createNewTemplateBulk",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/templates/bulk`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchAssessmentTemplate = createAsyncThunk(
  "assessmentTemplate/fetchAssessmentTemplate",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/templates/${templateId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createNewTemplate = createAsyncThunk(
  "assessmentTemplate/createNewTemplate",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/templates`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateTemplate = createAsyncThunk(
  "assessmentTemplate/updateTemplate",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData, templateId } = values;
      const res = await axios.patch(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/templates/${templateId}`,
        objPostData,
        { "Content-Type": "application/json" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const shareAssessmentTemplate = createAsyncThunk(
  "assessmentTemplate/share",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/templates/share/${templateId}`,
        {}
      );
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error);
      throw error;
    }
  }
);

export const unshareAssessmentTemplate = createAsyncThunk(
  "assessmentTemplate/unshare",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/templates/unshare/${templateId}`,
        {}
      );
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error);
      throw error;
    }
  }
);

export const getAssessmentEvents = createAsyncThunk(
  "assessmentEvent/get",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/events`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAssessmentEventsForReports = createAsyncThunk(
  "assessmentEvent/getAssessmentEventsForReports",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/events/for-reports`,
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAssessmentEventVersions = createAsyncThunk(
  "assessmentEvent/getVersions",
  async (values, thunkApi) => {
    try {
      const {
        objUser,
        assessment_template_id,
        completedEventsOnlychecked = null,
      } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/events/versions/all/${assessment_template_id}?completed_events_only=${completedEventsOnlychecked}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAssessmentEventVersionsCount = createAsyncThunk(
  "assessmentEvent/getVersionsCount",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/events/versions/all/count/${objPostData.templateId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createNewEvent = createAsyncThunk(
  "assessmentEvent/createNewEvent",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/events`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createNewEventBulk = createAsyncThunk(
  "assessmentEvent/createNewEventBulk",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/events/bulk`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchAssessmentEvent = createAsyncThunk(
  "assessmentEvent/fetchAssessmentEvent",
  async (values, thunkApi) => {
    try {
      const { objUser, eventId } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/events/${eventId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateEvent = createAsyncThunk(
  "assessmentEvent/updateEvent",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData, eventId } = values;
      const res = await axios.patch(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/events/${eventId}`,
        objPostData,
        { "Content-Type": "application/json" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteAssessmentEvent = createAsyncThunk(
  "assessmentEvent/delete",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData, eventId } = values;
      const res = await axios.delete(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/events/${eventId}`,
        {
          data: objPostData,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addToRiskMatrix = createAsyncThunk(
  "assessmentTemplate/addToRiskMatrix",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData, eventId } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/risk-matrix/${eventId}`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const bulkAddToRiskMatrix = createAsyncThunk(
  "assessmentTemplate/bulkAddToRiskMatrix",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData, eventId } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/risk-matrix/${eventId}/bulk`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateRiskMatrix = createAsyncThunk(
  "assessmentTemplate/updateRiskMatrix",
  async (values, thunkApi) => {
    try {
      const { objUser, objUpdateData, eventId } = values;
      const res = await axios.patch(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/risk-matrix/${eventId}`,
        objUpdateData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteRiskMatrixItem = createAsyncThunk(
  "assessmentTemplate/deleteRiskMatrix",
  async (values, thunkApi) => {
    try {
      const { objUser, riskMatrixItemId, eventId } = values;
      const res = await axios.delete(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/risk-matrix/${eventId}/${riskMatrixItemId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAssessmentReportPDF = createAsyncThunk(
  "assessments/get-assesemnent-report-pdf",
  async (values, thunkApi) => {
    try {
      const { objUser, objData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/events/report-pdf/${objData.id}`,
        objData,
        { responseType: "blob" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const uploadEvidenceToOneDrive = createAsyncThunk(
  "assessments/uploadEvidenceToOneDrive",
  async (values, thunkApi) => {
    try {
      const { objUser, selectedFile, event_question_id } = values;

      const formData = new FormData();
      formData.append("evidence", selectedFile);

      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/events/upload-to-one-drive/${event_question_id}`,
        // `${BASE_ENDPOINT}/${getCompanyID(objUser)}/logos/client/display-logo`,
        formData,
        { "Content-Type": "multipart/form-data" }
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const uploadEvidenceToSharePoint = createAsyncThunk(
  "assessments/uploadEvidenceToSharePoint",
  async (values, thunkApi) => {
    try {
      const { objUser, selectedFile, event_question_id } = values;

      const formData = new FormData();
      formData.append("evidence", selectedFile);

      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/events/upload-to-share-point/${event_question_id}`,
        formData,
        { "Content-Type": "multipart/form-data" }
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import {
  COMPANIES_SERVER,
  VERSION_SERVER,
  CLIENTS_SERVER,
  BASE_ENDPOINT,
} from "components/utils/constants/misc";

const getCompanyID = (objUser) => {
  return objUser.user.company_id;
};

/**categories */
export const getCategories = createAsyncThunk(
  "company/getCategories",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;
      const THIS_CLIENTS_SERVER = CLIENTS_SERVER.replace(
        "{companyId}",
        objUser.user.company_id
      );
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/categories/?per_page=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addCategory = createAsyncThunk(
  "company/addCategory",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/categories`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteCategoryData = createAsyncThunk(
  "company/deleteCategory",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;

      const res = await axios.delete(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/categories/${
          dataFromActionBtn.categoryId
        }`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveCategoryChanges = createAsyncThunk(
  "company/saveCategory",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.patch(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/categories/${
          objPostData.objPostData.id
        }`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**RMF */
export const getRMF = createAsyncThunk(
  "company/getRMF",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/rmf/?per_page=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addRMF = createAsyncThunk(
  "company/addCategory",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/rmf`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteRMFData = createAsyncThunk(
  "company/deleteRMFData",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;

      const res = await axios.delete(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/rmf/${
          dataFromActionBtn.rmfId
        }`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveRMFChanges = createAsyncThunk(
  "company/saveCategory",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.patch(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/rmf/${
          objPostData.objPostData.id
        }`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**CLIENT LOGOS */
export const uploadDisplayLogo = createAsyncThunk(
  "company/uploadDisplayLogo",
  async (values, thunkApi) => {
    try {
      const { objUser, file } = values;

      const formData = new FormData();
      formData.append("logo", file);

      const THIS_CLIENTS_SERVER = CLIENTS_SERVER.replace(
        "{companyId}",
        objUser.user.company_id
      );

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/logos/client/display-logo`,
        formData,
        { "Content-Type": "multipart/form-data" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchDisplayLogo = createAsyncThunk(
  "company/fetchDisplayLogo",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/logos/client/display-logo`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteClientDisplayLogo = createAsyncThunk(
  "company/deleteDisplayLogo",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.delete(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/logos/client/display-logo`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const uploadReportLogo = createAsyncThunk(
  "company/uploadReportLogo",
  async (values, thunkApi) => {
    try {
      const { objUser, reportFile } = values;

      const formData = new FormData();
      formData.append("logo", reportFile);

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/logos/client/report-logo`,
        formData,
        { "Content-Type": "multipart/form-data" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchReportLogo = createAsyncThunk(
  "company/fetchReportLogo",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/logos/client/report-logo`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteClientReportLogo = createAsyncThunk(
  "company/deleteReportLogo",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.delete(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/logos/client/report-logo`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchPublicLinkPassword = createAsyncThunk(
  "company/fetchPublicLinkPassword",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/clients/${
          objUser.user.current_client_id
        }/public-link-password`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updatePublicLinkPassword = createAsyncThunk(
  "company/updatePublicLinkPassword",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;

      const res = await axios.patch(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/clients/${
          objUser.user.current_client_id
        }/public-link-password`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updatePublicLinkPasswordActiveStatus = createAsyncThunk(
  "company/updatePublicLinkPasswordActiveStatus",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;

      const res = await axios.patch(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/clients/${
          objUser.user.current_client_id
        }/public-link-password/toggle`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const validatePassword = createAsyncThunk(
  "client/validatePassword",
  async (values, thunkApi) => {
    try {
      const { password, client_id, company_id } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${company_id}/clients/${client_id}/public-link-password/validate-password`,
        {
          password: password,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**COMPANY LOGOS */
export const uploadDisplayLogoMSP = createAsyncThunk(
  "company/uploadDisplayLogo",
  async (values, thunkApi) => {
    try {
      const { objUser, file } = values;

      const formData = new FormData();
      formData.append("logo", file);

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/logos/display-logo`,
        formData,
        { "Content-Type": "multipart/form-data" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchDisplayLogoMSP = createAsyncThunk(
  "company/fetchDisplayLogo",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/logos/display-logo`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteMSPDisplayLogo = createAsyncThunk(
  "company/deleteDisplayLogo",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/logos/display-logo`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const uploadReportLogoMSP = createAsyncThunk(
  "company/uploadReportLogo",
  async (values, thunkApi) => {
    try {
      const { objUser, reportFile } = values;

      const formData = new FormData();
      formData.append("logo", reportFile);

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/logos/report-logo`,
        formData,
        { "Content-Type": "multipart/form-data" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchReportLogoMSP = createAsyncThunk(
  "company/fetchReportLogo",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/logos/report-logo`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteMSPReportLogo = createAsyncThunk(
  "company/deleteReportLogo",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/logos/report-logo`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**company pdf settings */
export const fetchPDFDownloadSettings = createAsyncThunk(
  "company/fetchPDFDownloadSettings",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/pdf-download-config`
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updatePDFDownloadSettings = createAsyncThunk(
  "company/updatePDFDownloadSettings",
  async (values, thunkApi) => {
    try {
      const { objUser, pdfSettingValue, displayPdfSettingsValue } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/pdf-download-config`,
        { pdfSettingValue, displayPdfSettingsValue }
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**email delivery preferences */
export const fetchEmailDeliverySettings = createAsyncThunk(
  "company/fetchEmailDeliveryPreferences",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/email-delivery-settings`
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateEmailDeliverySettings = createAsyncThunk(
  "company/uploadEmailDeliverySettings",
  async (values, thunkApi) => {
    try {
      const { objUser, emailDeliverySettingValue } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/email-delivery-settings`,
        { emailDeliverySettingValue }
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**Client RMF Packs toggles */
export const saveRMFTogglesChanges = createAsyncThunk(
  "company/saveRMFTogglesChanges",
  async (values, thunkApi) => {
    try {
      const { objUser, currentToggles } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/rmf-toggles`,
        { currentToggles }
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// Auto Reload
export const fetchAutoLogoutTimer = createAsyncThunk(
  "company/fetchGamifycationStatsAssets",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/auto-logout-config`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const updateAutoLogoutTimer = createAsyncThunk(
  "company/authUpdateCompany",
  async (values, thunkApi) => {
    try {
      const { objUser, autoLogoutTimer } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/auto-logout-config`,
        { autoLogoutTimer }
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

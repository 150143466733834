import Card from "@mui/material/Card";
import Slider from "@mui/material/Slider";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
import CircularProgress from "@mui/material/CircularProgress";

import { unwrapResult } from "@reduxjs/toolkit";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { showMsg } from "utils/general";
import {
  fetchSubscriptionPlans,
  creteSubscriptionSession,
  createCustomerPortalSession,
} from "features/company/companiesActions.js";

import "./SignUpPickAPlan.css";

import { Grid } from "@mui/material";
import AgreeToPolygonPlusTermsDialog from "./AgreeToPolygonPlusTermsDialog";
import Pax8ManageBillingDialog from "./Pax8ManageBillingDialog";
import { displayExceptionErrorMessage } from "utils/general";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const SignUpPickAPlan = (props) => {
  const { objUser } = props;
  // console.log("::::: objuser :::::", objUser);
  const success = useLocation().pathname.split("/").slice(-1)[0] === "success";
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const [packageToSubscribe, setPackageToSubscribe] = useState("");
  const [isLoadingCustomerPortal, setIsLoadingCustomerPortal] = useState(false);

  const company = objUser.user.company;
  const subStatus = company ? company.status : null;

  /**Lite */
  const [plan, setPlan] = useState(0);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [currentStatus, setCurrentStatus] = useState({
    package: "lite",
    units: 0,
    status: "incomplete",
  });

  /**plus */
  const [plusPlan, setPlusPlan] = useState(0);
  const [plusSubscriptionPlans, setPlusSubscriptionPlans] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //tabs
  const [tabValue, setTabValue] = useState(0);

  //terms and conditions modal

  const [open, setOpen] = useState(false);
  const [openPax8ManageBillingDialog, setOpenPax8ManageBillingDialog] =
    useState(false);
  const [stripeCheckoutURL, setStripeCheckoutURL] = useState("");

  const onClose = () => {
    setOpen(false);
    setOpenPax8ManageBillingDialog(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  /**subscription plans is the state variable that stores the available products returned from BE */
  /**
   * Breakdown of slider logic
   * - Fetching subscription plans returns the available products and the current units the MSP has
   * - We set the current plan to the index of the MSP's units in the available products array.
   *  - For example, if my current plan has 30 units in lite, then the the index of the 30 units product is 6 in the array of products
   *   - So plan value will be set to 6
   * - The slider is rendered with the marks object (value, and label) Label is the units, say 2, 30, 40
   * --- value is all the indexes of the available products packages.
   * --- So automatically, current plan index will intersect with the mark that has the same index
   */

  /**lite */
  const marks = subscriptionPlans.map((plan, i) => {
    return {
      value: i,
      label: plan.value, //plan.value corresponds to the number of units
    };
  });

  /**plus */
  const marksPlus = plusSubscriptionPlans.map((plan, i) => {
    return {
      value: i,
      label: plan.value, //plan.value corresponds to the number of units
    };
  });

  useEffect(() => {
    if (success) {
      showMsg("success", "Your subscription was successfully upgraded.");
      localStorage.removeItem("guestSelectedUnitsToSubscribeToAfterSignup");
    }

    if (
      typeof objUser.user !== "undefined" &&
      typeof objUser.user.email_verified_at !== "undefined"
    ) {
      const objAuthUser = objUser.user;

      // Only admin users can access billing
      if (objAuthUser.role?.name !== "admin" || objAuthUser.company === null) {
        navigate("/home");
      }
    } else {
      navigate("/");
    }

    setIsLoading(true);
    dispatch(fetchSubscriptionPlans({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const result = originalPromiseResult.data;
        let guest_selected_units = localStorage.getItem(
          "guestSelectedUnitsToSubscribeToAfterSignup"
        );
        let currentPlan;
        let currentPlusPlan;
        if (
          guest_selected_units &&
          result.subscription_status == "PENDING_SUBSCRIPTION"
        ) {
          currentPlan = result.available_products.findIndex(
            (plan) => plan.units == guest_selected_units
          );
        } else {
          currentPlan = result.available_products.findIndex(
            (plan) => plan.units === result.current_units - 1
          );
          currentPlusPlan = result.available_plus_products.findIndex(
            (plan) => plan.units === result.current_units - 1
          );
        }

        /**lite */
        setPlan(
          currentPlan === -1 || result.current_subscription_package != "lite"
            ? 0
            : currentPlan
        );

        /**plus */
        setPlusPlan(
          currentPlusPlan === -1 ||
            result.current_subscription_package != "plus"
            ? 0
            : currentPlusPlan
        );

        setCurrentStatus({
          package: result.current_subscription_package,
          units: (result.current_units ?? 1) - 1,
          status: result.subscription_status ?? "incomplete",
        });

        /**lite */
        setSubscriptionPlans(
          result.available_products.map((plan) => {
            return {
              name: plan?.name,
              value: plan.units,
              price: plan.price,
              price_str: plan?.price_str,
              price_x_client: plan.price_x_client,
              price_x_client_str: plan.price_x_client_str,
              monthly_price_id: plan.monthly_price_id,
            };
          })
        );

        /**plus */
        setPlusSubscriptionPlans(
          result.available_plus_products.map((plan) => {
            return {
              name: plan?.name,
              value: plan.units,
              price: plan.price,
              price_str: plan?.price_str,
              price_x_client: plan.price_x_client,
              price_x_client_str: plan.price_x_client_str,
              monthly_price_id: plan.monthly_price_id,
            };
          })
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  /**lite */
  const valuetext = (value) => {
    return subscriptionPlans[value].value + " users";
  };

  const valueLabelFormat = (value) => {
    return `${subscriptionPlans[value]?.price_str}`;
  };

  /**plus */
  const valuePlustext = (value) => {
    return plusSubscriptionPlans[value]?.value + " users";
  };

  const valuePlusLabelFormat = (value) => {
    return `${plusSubscriptionPlans[value]?.price_str}`;
  };

  const handleSubscribe =
    (
      price_id,
      new_price,
      new_units,
      package_to_subscribe,
      signup_for_free_trial = "no"
    ) =>
    () => {
      if (isProcessingRequest) return;
      setIsProcessingRequest(true);
      setPackageToSubscribe(package_to_subscribe);
      dispatch(
        creteSubscriptionSession({
          objUser,
          price_id,
          new_price,
          new_units,
          signup_for_free_trial,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          if (result.data === window.location.href) {
            window.history.go(0);
          } else {
            // if (package_to_subscribe == "plus") {
            setOpen(true);
            setStripeCheckoutURL(result.data);
            // } else {
            //   window.location.href = result.data;
            // }
          }
          setIsProcessingRequest(false);
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsProcessingRequest(false);
          displayExceptionErrorMessage(rejectedValueOrSerializedError);
        });
    };

  const onClickBillingsManagement = () => () => {
    if (isLoadingCustomerPortal) return;
    setIsLoadingCustomerPortal(true);
    dispatch(createCustomerPortalSession({ objUser }))
      .then(unwrapResult)
      .then((result) => {
        setIsLoadingCustomerPortal(false);
        if (
          typeof result.data.url !== "undefined" &&
          result.data.url !== null &&
          result.data.url !== ""
        ) {
          window.location.href = result.data.url;
          return;
        }
        showMsg("error", "Something went wrong, please try again.");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingCustomerPortal(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title="Choose your plan" />
      <AgreeToPolygonPlusTermsDialog
        open={open}
        onClose={onClose}
        stripeCheckoutURL={stripeCheckoutURL}
        packageToSubscribe={packageToSubscribe}
      />
      <Pax8ManageBillingDialog
        open={openPax8ManageBillingDialog}
        onClose={onClose}
      />

      <MDBox className="billing-page">
        {isLoading ? (
          <MDBox mb={4}>
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          </MDBox>
        ) : (
          <>
            {objUser.user.company !== "undefined" &&
            objUser.user.company.hcid !== "undefined" &&
            objUser.user.company.hcid &&
            currentStatus.package == "lite" ? (
              <MDBox mt={4} mb={2}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={onClickBillingsManagement()}
                >
                  Go To Billings Management Portal
                </MDButton>
              </MDBox>
            ) : (
              <></>
            )}

            {/* 2 column pricing sliders */}
            <MDBox mt={4} mb={4}>
              <Grid spacing={2} container justifyContent="center" width="100%">
                {/* plus */}
                <Grid item xs={12} lg={6}>
                  <Card sx={{ height: "100%" }}>
                    <MDBox
                      className="card-header"
                      sx={{
                        backgroundColor: "#000",
                        borderTopLeftRadius: "0.75rem",
                        borderTopRightRadius: "0.75rem",
                      }}
                      px={2}
                      py={1}
                    >
                      <MDTypography
                        sx={{
                          color: "#fff",
                          textAlign:
                            objUser.user.company.default_payment_medium ===
                            "stripe"
                              ? "left"
                              : "center",
                        }}
                        variant="h5"
                        fontWeight="bold"
                      >
                        Compliance Scorecard Plus -{" "}
                        <em>Minimum 1 Year Commitment</em>
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      mt={4}
                    >
                      <MDTypography
                        variant="body1"
                        mb={0}
                        sx={{
                          textAlign: "center",
                          color: "#1A1A1A",
                          fontSize: "1.2rem",
                        }}
                      >
                        How many clients do you want to support?
                      </MDTypography>
                      <MDTypography
                        fontWeight="bold"
                        variant="caption"
                        color="success"
                        mb={2}
                        sx={{ textAlign: "center", fontSize: ".9rem" }}
                      >
                        You are currently on a Compliance Scorecard{" "}
                        {capitalizeFirstLetter(currentStatus.package)} plan that
                        supports {currentStatus.units} clients
                      </MDTypography>

                      <Card
                        className="custom-slider hl-border bg-light"
                        sx={{ marginBottom: "16px" }}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                          mb={0}
                          px={4}
                          py={2}
                        >
                          <MDBox className="slider-container">
                            <Slider
                              color="secondary"
                              aria-label="Choose your plan"
                              value={plusPlan}
                              onChange={(_, value) => setPlusPlan(value)}
                              valueLabelFormat={valuePlusLabelFormat}
                              getAriaValueText={valuePlustext}
                              step={null}
                              valueLabelDisplay="auto"
                              marks={marksPlus}
                              max={marksPlus.length - 1}
                            />
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>

                    <MDBox mb={4} mt={1}>
                      <Card
                        className="custom-card custom-product-card hl-border-dark bg-light hl-shadow"
                        sx={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: "0",
                          marginTop: "0",
                        }}
                      >
                        <MDBox
                          className="card-header bg-secondary"
                          px={2}
                          py={1}
                        >
                          <MDTypography
                            sx={{ color: "#FFFFFF" }}
                            variant="h5"
                            fontWeight="bold"
                          >
                            {plusSubscriptionPlans[plusPlan]?.name}
                          </MDTypography>
                        </MDBox>
                        <MDBox className="card-body" p={2}>
                          <span className="price-per-client-plus">
                            {plusSubscriptionPlans[plusPlan]?.price_x_client_str}{" "}
                          </span>
                          <span className="price-client-label"> / client</span>
                          <span className="price-month">
                            {plusSubscriptionPlans[plusPlan]?.price_str}
                          </span>
                          <span className="price-month-label"> / mo</span>

                          <MDBox my={1}>
                            <MDTypography variant="h5" fontWeight="bold">
                              Compliance Scorecard Plus Plan Includes:
                            </MDTypography>
                          </MDBox>

                          <MDBox className="all-plans-include-section">
                            <ul className="list-unstyled mt-3 mb-4">
                              <li>
                                <span>
                                  <i className="fa-sharp fa-solid fa-check"></i>
                                  &nbsp;
                                  <b>
                                    Always free internal use when on a paid
                                    plan:{" "}
                                  </b>{" "}
                                  Access our platform at no cost when you’re on
                                  any paid plan.
                                </span>
                              </li>
                              <li>
                                <span>
                                  <i className="fa-sharp fa-solid fa-check"></i>
                                  &nbsp;{" "}
                                  <b>White Glove Onboarding (2 Sessions): </b>
                                  <ul style={{ marginBottom: 0 }}>
                                    <li>
                                      <b>Technical Onboarding:</b> Learn
                                      platform navigation, API setups, and more.{" "}
                                    </li>
                                    <li>
                                      <b>Sales and Market Strategy:</b>{" "}
                                      Collaborative planning for package
                                      development, pricing strategies, and
                                      profit optimization{" "}
                                    </li>
                                  </ul>
                                </span>
                              </li>
                              <li>
                                <span>
                                  <i className="fa-sharp fa-solid fa-check"></i>
                                  &nbsp; <b>Assessment Scorecard: </b>
                                  <ul style={{ marginBottom: 0 }}>
                                    <li>
                                      <b>Customizable Assessments:</b> Create
                                      and import assessments, choose from
                                      pre-defined templates for standards like
                                      HIPAA, NIST, FTC, SOC2, and more.
                                    </li>
                                    <li>
                                      <b>Efficient Deployment: </b> Answer once,
                                      deploy to multiple clients with
                                      customization options at each endpoint.
                                    </li>
                                    <li>
                                      <b>Lifecycle Management: </b> Full control
                                      from creation to completion, including
                                      custom scoring and collaborative item
                                      assignment.
                                    </li>
                                    <li>
                                      <b>Advanced Analysis: </b> Track changes
                                      over time with comparative analytics,
                                      generate risk matrix reports, and system
                                      security plans.
                                    </li>
                                    <li>
                                      <b>Risk Management: </b> Set risk levels,
                                      assign gaps to a risk register, and create
                                      actionable plans and milestones.
                                    </li>
                                  </ul>
                                </span>
                              </li>
                              <li>
                                <span>
                                  <i className="fa-sharp fa-solid fa-check"></i>
                                  &nbsp; <b>Policy Scorecard: </b>
                                  <ul style={{ marginBottom: 0 }}>
                                    <li>
                                      <b>Template Management:</b> Create and
                                      edit global templates, including over 30
                                      policy frameworks updated monthly.
                                    </li>
                                    <li>
                                      <b>Efficient Policy Deployment: </b> Write
                                      once, deploy universally.
                                    </li>
                                    <li>
                                      <b>Comprehensive Policy Tools: </b>{" "}
                                      Generate WISPs, manage workflows, review
                                      and approval processes, and utilize full
                                      WYSIWYG editing.
                                    </li>
                                  </ul>
                                </span>
                              </li>
                              <li>
                                <span>
                                  <i
                                    className="fa fa-files-o"
                                    aria-hidden="true"
                                  ></i>
                                  &nbsp;
                                  <b>
                                    Existing document updates as frameworks
                                    change:{" "}
                                  </b>{" "}
                                  Streamlined management of assets, written
                                  information security policies, and insurance
                                  compliance.
                                </span>
                              </li>
                              <li>
                                <span>
                                  <i className="fa-sharp fa-solid fa-check"></i>
                                  &nbsp; <b>Platform Features: </b>
                                  <ul>
                                    <li>
                                      <b>Multi-Tenant Architecture:</b> Manage
                                      multiple clients over a single interface
                                    </li>
                                    <li>
                                      <b>Enhanced Security: </b>Single Sign-On
                                      (SSO), Multi-Factor Authentication (MFA),
                                      and Microsoft 365 integration.
                                    </li>
                                    <li>
                                      <b>Unlimited Access: </b> Support for
                                      unlimited users and version control with
                                      redlining capabilities.
                                    </li>
                                  </ul>
                                </span>
                              </li>
                            </ul>
                          </MDBox>
                        </MDBox>
                        <MDBox className="card-footer" px={2} pb={2} pt={2}>
                          {currentStatus.units ===
                            plusSubscriptionPlans[plusPlan]?.value &&
                          currentStatus.status === "active" &&
                          currentStatus.package == "plus" ? (
                            <MDButton
                              variant="contained"
                              color="info"
                              sx={{ width: "100%" }}
                            >
                              Current Plan
                            </MDButton>
                          ) : (
                            <Fragment>
                              {objUser.user.company.default_payment_medium ===
                              "stripe" ? (
                                <MDButton
                                  onClick={handleSubscribe(
                                    plusSubscriptionPlans[plusPlan]
                                      ?.monthly_price_id,
                                    plusSubscriptionPlans[plusPlan]?.price,
                                    plusSubscriptionPlans[plusPlan]?.value,
                                    "plus"
                                  )}
                                  variant="contained"
                                  color={
                                    currentStatus.status === "active" &&
                                    currentStatus.units >
                                      plusSubscriptionPlans[plusPlan]?.value &&
                                    currentStatus.package == "plus"
                                      ? "error"
                                      : "success"
                                  }
                                  sx={{ width: "100%" }}
                                  disabled={
                                    plusSubscriptionPlans[plusPlan]?.monthly_price_id === null
                                  }
                                >
                                  {isProcessingRequest &&
                                  packageToSubscribe == "plus" ? (
                                    <CircularProgress color="white" size={15} />
                                  ) : currentStatus.status === "active" &&
                                    currentStatus.units <
                                      plusSubscriptionPlans[plusPlan]?.value &&
                                    currentStatus.package == "plus" ? (
                                    "Upgrade"
                                  ) : currentStatus.status === "active" &&
                                    currentStatus.units >
                                      plusSubscriptionPlans[plusPlan]?.value &&
                                    currentStatus.package == "plus" ? (
                                    "Downgrade"
                                  ) : (
                                    "Subscribe"
                                  )}
                                </MDButton>
                              ) : (
                                /* Button for Pax8 subscribers to manage subscriptions */
                                <MDButton
                                  variant="contained"
                                  color={"success"}
                                  sx={{ width: "100%" }}
                                  text="Subscribe"
                                  onClick={() =>
                                    setOpenPax8ManageBillingDialog(true)
                                  }
                                >
                                  Manage subscription
                                </MDButton>
                              )}
                            </Fragment>
                          )}
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Card>
                </Grid>
                {/* end plus */}

                {/* peer group */}
                {objUser.user.company.default_payment_medium === "stripe" && (
                  <Grid item xs={12} lg={6}>
                    <Card sx={{ height: "100%" }}>
                      <MDBox
                        className="card-header"
                        sx={{
                          backgroundColor: "#4d4c5c",
                          borderTopLeftRadius: "0.75rem",
                          borderTopRightRadius: "0.75rem",
                        }}
                        px={2}
                        py={1}
                      >
                        <MDTypography
                          sx={{ color: "#fff" }}
                          variant="h5"
                          fontWeight="bold"
                        >
                          Peer Group Plan
                          {/* <em>Minimum 1 Year Commitment</em> */}
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        mt={4}
                      >
                        <stripe-pricing-table
                          pricing-table-id={
                            process.env.REACT_APP_SECONDARY_PICK_A_PLAN_TABLE_ID
                          }
                          publishable-key={
                            process.env
                              .REACT_APP_SECONDARY_STRIPE_PUBLISHABLE_KEY
                          }
                          customer-email={objUser.user.company.email}
                          client-reference-id={objUser.user.company.id}
                        ></stripe-pricing-table>
                      </MDBox>
                    </Card>
                  </Grid>
                )}
                {/* end peer group */}

                {/* lite 
                 If the user is on a Compliance Scorecard plus which is higher than every single lite plan, then it 
                 means, no available product will be returned for lite so we need to make 
                 sure that the only way the lite slider is shown is when the person is on lite plan AND is 
                 not on a plus plan which is higher than all light
                */}
                {/* end lite */}
              </Grid>
            </MDBox>
            {/* end two column pricing sliders */}
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default SignUpPickAPlan;

import { useState, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { showMsg, standardDateFormat } from "utils/general";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import AuditLogsDataTableData from "./components/AuditLogsDataTableData";
import AuditLogsTable from "./components/AuditLogsTable";
import {
  listAuditLogs,
  listNoAuth0AuditLogs,
} from "features/company/auditActions";
import { displayExceptionErrorMessage } from "utils/general";

const ListAuditLogs = (props) => {
  const [isLoadingMoreLogs, setIsLoadingMoreLogs] = useState(false);
  const [hideAuthOLogs, setHideAuthOLogs] = useState(0);
  const [objTableData, setObjTableData] = useState(AuditLogsDataTableData);
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    loadMoreLogs(perPage, page);
  }, []);

  const loadMoreLogs = (_per_page, _page, hideAuth) => {
    if (hideAuth == undefined) {
      hideAuth = hideAuthOLogs;
    }
    setIsLoadingMoreLogs(true);
    dispatch(
      hideAuth == 1
        ? listNoAuth0AuditLogs({ _per_page, _page })
        : listAuditLogs({ _per_page, _page })
    )
      .then(unwrapResult)
      .then((response) => {
        setTotalCount(response.data.total);
        const row = response.data.data.map((el) => {
          if (el.causer === null) {
            el.causer = {
              name: "Anonymous User",
            };
          }
          el.created_at = standardDateFormat(
            el.created_at,
            "include_timestamp"
          );
        });

        setObjTableData({
          ...AuditLogsDataTableData,
          rows: response.data.data,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      })
      .finally(() => setIsLoadingMoreLogs(false));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card sx={{ marginTop: "16px" }}>
          {isLoadingMoreLogs === false &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <AuditLogsTable
              entriesPerPage={true}
              table={objTableData}
              canSearch
              totalCount={totalCount}
              loadMoreLogs={loadMoreLogs}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              hideAuthOLogs={hideAuthOLogs}
              setHideAuthOLogs={setHideAuthOLogs}
            />
          ) : (
            <Fragment>
              {isLoadingMoreLogs ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>No logs to show</p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default ListAuditLogs;

import { useEffect, useState, useMemo, memo } from "react";
import Card from "@mui/material/Card";

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useConfirm } from "material-ui-confirm";
import { useDispatch } from "react-redux";
import { WVList } from "virtua";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";

import { unwrapResult } from "@reduxjs/toolkit";
import {
  fetchAssessmentTemplate,
  updateTemplate,
} from "features/company/assessmentsActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";

import Icon from "@mui/material/Icon";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";

import tinycolor from "tinycolor2";
import { TextField } from "@mui/material";
import "./styles.css";
import { unPublishGlobalAssessmentTemplate } from "features/company/superAdminActions";
import { publishGlobalAssessmentTemplate } from "features/company/superAdminActions";

const confirmDefaultProps = {
  description: "",
  confirmationButtonProps: {
    color: "success",
    variant: "contained",
    sx: {
      backgroundColor: "#4CAF50",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#1b5e20",
        color: "#fff",
        boxShadow:
          "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
      },
    },
  },
  cancellationButtonProps: {
    color: "error",
    variant: "contained",
    sx: {
      backgroundColor: "#d32f2f",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
        color: "#fff",
        boxShadow:
          "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
      },
    },
  },
};

const generateHashId = () => {
  return Math.random().toString(36).substring(2, 15) + "_";
};

const QuestionDetails = memo(
  ({
    question,
    moveQuestionUp,
    moveQuestionDown,
    removeQuestion,
    handleQuestionChange,
    categoryKey,
    questionIndex,
    scoreColors,
    moveOptionDown,
    moveOptionUp,
    removeOption,
    handleOptionChange,
    scores,
    newOption,
  }) => {
    return (
      <div>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: "1rem",
          }}
        >
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tooltip title="Move Up" placement="bottom">
              <MDButton
                variant="gradient"
                color="light"
                iconOnly={true}
                size="small"
                onClick={(event) => moveQuestionUp(event, question.id)}
              >
                <Icon color="dark">keyboard_arrow_up</Icon>
              </MDButton>
            </Tooltip>
            <Tooltip title="Move Down" placement="bottom">
              <MDButton
                variant="gradient"
                color="light"
                iconOnly={true}
                size="small"
                onClick={(event) => moveQuestionDown(event, question.id)}
              >
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
            </Tooltip>
            <Tooltip title="Remove this question" placement="bottom">
              <MDButton
                variant="gradient"
                color="error"
                iconOnly={true}
                size="small"
                onClick={(event) => removeQuestion(event, question.id)}
              >
                <Icon>delete</Icon>
              </MDButton>
            </Tooltip>
          </MDBox>
          <MDBox flexGrow="1">
            <TextField
              name="item"
              label="Question"
              value={question.item}
              onChange={(event) =>
                handleQuestionChange(event, categoryKey, questionIndex)
              }
              variant="standard"
              fullWidth
              multiline
            />
            <TextField
              name="control_id"
              label="Control ID"
              value={question.control_id}
              onChange={(event) =>
                handleQuestionChange(event, categoryKey, questionIndex)
              }
              variant="standard"
              fullWidth
              multiline
            />
            <TextField
              name="subcategory"
              label="Sub Category"
              value={question.subcategory}
              onChange={(event) =>
                handleQuestionChange(event, categoryKey, questionIndex)
              }
              variant="standard"
              fullWidth
              multiline
            />
            <TextField
              name="explanation"
              label="Explanation"
              value={question.explanation ?? ""}
              onChange={(event) =>
                handleQuestionChange(event, categoryKey, questionIndex)
              }
              variant="standard"
              fullWidth
              multiline
            />
            <TextField
              name="scoring_instructions"
              label="Scoring Instructions"
              value={question.scoring_instructions ?? ""}
              onChange={(event) =>
                handleQuestionChange(event, categoryKey, questionIndex)
              }
              variant="standard"
              fullWidth
              multiline
            />
            <TextField
              name="sprs_score"
              type="number"
              label="SPRS Score"
              value={question.sprs_score}
              onChange={(event) =>
                handleQuestionChange(event, categoryKey, questionIndex)
              }
              variant="standard"
              fullWidth
            />
            <MDTypography variant="h5" mt={1} mb={1}>
              Options
            </MDTypography>
            {question.options.map((option, optionIndex) => (
              <Card
                key={option.id}
                variant="outlined"
                sx={{
                  padding: "1rem",
                  marginBottom: "0.5rem",
                  boxShadow: `0rem 0.25rem 0.375rem -0.0625rem ${
                    scoreColors[option.score]?.setAlpha(0.5)?.toHex8String() ??
                    "#eeeeee75"
                  }, 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)`,
                }}
              >
                <MDBox display="flex" justifyContent="flex-end" gap="0.4rem">
                  <Tooltip title="Move Down" placement="bottom">
                    <MDButton
                      variant="gradient"
                      color="light"
                      iconOnly={true}
                      size="small"
                      onClick={(event) =>
                        moveOptionDown(event, question.id, option.id)
                      }
                    >
                      <Icon>keyboard_arrow_down</Icon>
                    </MDButton>
                  </Tooltip>
                  <Tooltip title="Move Up" placement="bottom">
                    <MDButton
                      variant="gradient"
                      color="light"
                      iconOnly={true}
                      size="small"
                      onClick={(event) =>
                        moveOptionUp(event, question.id, option.id)
                      }
                    >
                      <Icon>keyboard_arrow_up</Icon>
                    </MDButton>
                  </Tooltip>
                  <Tooltip title="Remove this option" placement="bottom">
                    <MDButton
                      variant="gradient"
                      color="error"
                      iconOnly={true}
                      size="small"
                      onClick={(event) =>
                        removeOption(event, question.id, option.id)
                      }
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </Tooltip>
                </MDBox>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12} md={5} p={1}>
                    <MDInput
                      name="option"
                      label="Option"
                      value={option.option}
                      onChange={(event) =>
                        handleOptionChange(
                          event,
                          categoryKey,
                          questionIndex,
                          optionIndex
                        )
                      }
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} p={1}>
                    <MDInput
                      name="explanation"
                      label="Option Explanation"
                      value={option.explanation ?? ""}
                      onChange={(event) =>
                        handleOptionChange(
                          event,
                          categoryKey,
                          questionIndex,
                          optionIndex
                        )
                      }
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3} p={1}>
                    <Autocomplete
                      disableClearable
                      name="score"
                      options={scores}
                      getOptionLabel={(option) =>
                        option.label ?? scores[option].label
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      renderOption={(props, option) => {
                        if (typeof option !== "object") {
                          option = scores[option];
                        }
                        return (
                          <MDBox component="li" {...props}>
                            <MDBox
                              component="span"
                              sx={{
                                backgroundColor:
                                  scoreColors[option.id]?.toHex8String() ??
                                  "#eee",
                                padding: "5px",
                                borderRadius: "100%",
                                marginRight: "0.5rem",
                              }}
                            />
                            {option.label}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="standard"
                          label="Score"
                          name="score"
                          fullWidth
                        />
                      )}
                      value={option.score}
                      onChange={(event, value) =>
                        handleOptionChange(
                          { target: { name: "score", value: value.id } },
                          categoryKey,
                          questionIndex,
                          optionIndex
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Card>
            ))}
            <MDTypography
              variant="button"
              color="success"
              onClick={() => newOption(question.id)}
              sx={{ cursor: "pointer" }}
            >
              <MDBox
                display="flex"
                gap=".5rem"
                alignItems="center"
                color="success"
                mt={1}
                mb={1}
              >
                <Icon>add</Icon>
                Add Option
              </MDBox>
            </MDTypography>
          </MDBox>
        </div>
      </div>
    );
  }
);

const subscription_categories = ["lite", "plus"];

const EditAssessmentTemplates = (props) => {
  const { objUser, backUrl, editGlobal } = props;
  const { template_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [templateData, setTemplateData] = useState({});
  const [categories, setCategories] = useState({});
  const [itemScores, setItemScores] = useState([{}]);
  const [types, setTypes] = useState([{}]);
  const [isEditing, setIsEditing] = useState(false);
  const [status, setTemplateStatus] = useState("Draft");
  const [isReturn, setIsReturn] = useState(false);
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const findCategoryKey = (categoryName) => {
    return Object.keys(categories).find(
      (key) => categories[key] === categoryName
    );
  };

  const sortQuestionsIntoCategories = (objTemplate) => {
    const catArray = {};
    objTemplate.category = {};
    objTemplate.questions.sort((a, b) => a.sequence - b.sequence);
    for (let index = 0; index < objTemplate.questions.length; index++) {
      const element = objTemplate.questions[index];
      element.options.sort((a, b) => a.sequence - b.sequence);

      let catKey = Object.keys(catArray).find(
        (key) => catArray[key] === element.category
      );
      if (typeof catKey === "undefined") {
        catKey = generateHashId();
        catArray[catKey] = element.category;
      }

      if (typeof objTemplate.category[catKey] === "undefined") {
        objTemplate.category[catKey] = [];
      }

      objTemplate.category[catKey].push(element);
    }

    setCategories(catArray);

    return objTemplate;
  };

  const fetchTemplate = (template_id) => {
    setIsLoading(true);
    dispatch(fetchAssessmentTemplate({ objUser, templateId: template_id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;

        const objTemplate = sortQuestionsIntoCategories(
          response.data?.template
        );
        const types = response.data?.types;

        setItemScores(response.data?.itemScores);
        setTypes(types);
        setTemplateData(objTemplate);
        setTemplateStatus(objTemplate.status);
      })
      .catch((rejectedValueOrSerializedError) => {
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
        } else {
          showMsg("error", objErrorRsp?.data?.message);
          showMsg("error", objErrorRsp?.data?.error_code);
        }
        navigate(backUrl, { state: routeDictionary });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchTemplate(template_id);
  }, []);

  const scoreColors = useMemo(() => {
    const colors = {};
    if (!Array.isArray(itemScores) || itemScores.length === 0) return colors;
    itemScores.forEach((itemScore) => {
      colors[itemScore.code] = tinycolor(itemScore.color);
    });
    return colors;
  }, [itemScores]);

  const scores = useMemo(() => {
    const scores = [];
    if (!Array.isArray(itemScores) || itemScores.length === 0) return scores;
    itemScores.forEach((itemScore) => {
      scores.push({ id: itemScore.code, label: itemScore.name });
    });
    return scores;
  }, [itemScores]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTemplateData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleQuestionChange = (event, categoryKey, questionIndex) => {
    const { name, value } = event.target;
    const newTemplateData = { ...templateData };
    newTemplateData.category[categoryKey][questionIndex][name] = value;
    setTemplateData(newTemplateData);
    setIsEditing(true);
  };

  const handleOptionChange = (
    event,
    categoryKey,
    questionIndex,
    optionIndex
  ) => {
    const { name, value } = event.target;
    const newTemplateData = { ...templateData };
    newTemplateData.category[categoryKey][questionIndex].options[optionIndex][
      name
    ] = value;
    setTemplateData(newTemplateData);
    setIsEditing(true);
  };

  const handleCategoryChange = (event, categoryKey) => {
    const { value } = event.target;
    const newTemplateData = { ...templateData };
    const newCategoryData = { ...categories };

    newCategoryData[categoryKey] = value;
    newTemplateData.category[categoryKey].forEach((question) => {
      question.category = value;
    });

    setTemplateData(newTemplateData);
    setCategories(newCategoryData);
    setIsEditing(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSaving(true);

    const submitData = { ...templateData };
    delete submitData.category;
    submitData.questions = submitData.questions.map((question) => {
      if (question.id?.startsWith("__new__")) {
        question.id = null;
      }
      question.options = question.options.map((option) => {
        if (option.id?.startsWith("__new__")) {
          option.id = null;
        }
        return option;
      });
      return question;
    });
    submitData.assessment_type_id = submitData.type.id;

    dispatch(
      updateTemplate({
        objUser,
        templateId: template_id,
        objPostData: submitData,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        showMsg("success", response.data);
        if (isReturn) {
          navigate(backUrl, { state: routeDictionary });
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.message
        );
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.error_code
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const reSequenceQuestions = (templateData) => {
    let sequence = 1;
    Object.entries(templateData.category).forEach(([category, questions]) => {
      questions.forEach((question) => {
        question.sequence = sequence;
        sequence++;
      });
    });
    templateData.questions = templateData.questions.sort(
      (a, b) => a.sequence - b.sequence
    );
    return templateData;
  };

  const newCategory = () => {
    const newTemplateData = { ...templateData };
    let newCategoryName = "New Category";
    let index = 1;
    while (Object.values(categories).includes(newCategoryName)) {
      newCategoryName = `New Category ${index}`;
      index++;
    }

    const newCategoryData = { ...categories };
    const newCategoryKey = generateHashId();
    newCategoryData[newCategoryKey] = newCategoryName;
    setCategories(newCategoryData);

    newTemplateData.category[newCategoryKey] = [];
    setTemplateData(newTemplateData);
    setIsEditing(true);
  };

  const removeCategory = async (event, categoryKey) => {
    event.stopPropagation();
    try {
      await confirm({
        ...confirmDefaultProps,
        description: "Are you sure you want to remove this category?",
      });
    } catch (error) {
      return;
    }
    const category = categories[categoryKey];
    const newTemplateData = { ...templateData };
    newTemplateData.questions = newTemplateData.questions.filter(
      (question) => question.category !== category
    );
    delete newTemplateData.category[categoryKey];
    setTemplateData(newTemplateData);
    const newCategoryData = { ...categories };
    delete newCategoryData[categoryKey];
    setCategories(newCategoryData);
    setIsEditing(true);
  };

  const moveCategoryUp = (event, categoryKey) => {
    event.stopPropagation();
    const newTemplateData = { ...templateData };
    const categoryIndex = Object.keys(newTemplateData.category).indexOf(
      categoryKey
    );
    if (categoryIndex > 0) {
      const newCategoryOrder = Object.keys(newTemplateData.category);
      newCategoryOrder[categoryIndex] = newCategoryOrder[categoryIndex - 1];
      newCategoryOrder[categoryIndex - 1] = categoryKey;
      newTemplateData.category = Object.fromEntries(
        Object.entries(newTemplateData.category).sort(
          ([a], [b]) =>
            newCategoryOrder.indexOf(a) - newCategoryOrder.indexOf(b)
        )
      );

      // Change sequence of questions
      reSequenceQuestions(newTemplateData);
    }
    setTemplateData(newTemplateData);
    setIsEditing(true);
  };

  const moveCategoryDown = (event, categoryKey) => {
    event.stopPropagation();
    const newTemplateData = { ...templateData };
    const categoryIndex = Object.keys(newTemplateData.category).indexOf(
      categoryKey
    );
    if (categoryIndex < Object.keys(newTemplateData.category).length - 1) {
      const newCategoryOrder = Object.keys(newTemplateData.category);
      newCategoryOrder[categoryIndex] = newCategoryOrder[categoryIndex + 1];
      newCategoryOrder[categoryIndex + 1] = categoryKey;
      newTemplateData.category = Object.fromEntries(
        Object.entries(newTemplateData.category).sort(
          ([a], [b]) =>
            newCategoryOrder.indexOf(a) - newCategoryOrder.indexOf(b)
        )
      );

      // Change sequence of questions
      reSequenceQuestions(newTemplateData);
    }
    setTemplateData(newTemplateData);
    setIsEditing(true);
  };

  const newQuestion = (categoryKey) => {
    const newTemplateData = { ...templateData };
    const category = categories[categoryKey];
    const newQuestion = {
      id: "__new__" + new Date().getTime(),
      item: "",
      explanation: "",
      category: category,
      sequence: 0,
      options: [],
    };
    newTemplateData.questions.push(newQuestion);
    newTemplateData.category[categoryKey].push(newQuestion);
    // Reorder the questions
    reSequenceQuestions(newTemplateData);
    setTemplateData(newTemplateData);
  };

  const removeQuestion = async (event, questionId) => {
    event.stopPropagation();
    try {
      await confirm({
        ...confirmDefaultProps,
        description: "Are you sure you want to remove this question?",
      });
    } catch (error) {
      return;
    }
    const newTemplateData = { ...templateData };
    const question = newTemplateData.questions.find(
      (question) => question.id === questionId
    );
    newTemplateData.questions = newTemplateData.questions.filter(
      (question) => question.id !== questionId
    );
    const categoryKey = findCategoryKey(question.category);
    newTemplateData.category[categoryKey] = newTemplateData.category[
      categoryKey
    ].filter((question) => question.id !== questionId);
    // Reorder the questions
    newTemplateData.questions = newTemplateData.questions.map(
      (question, index) => {
        question.sequence = index + 1;
        return question;
      }
    );

    setTemplateData(newTemplateData);
    setIsEditing(true);
  };

  const moveQuestionUp = (event, questionId) => {
    event.stopPropagation();
    const newTemplateData = { ...templateData };
    const questionIndex = newTemplateData.questions.findIndex(
      (question) => question.id === questionId
    );
    const question = newTemplateData.questions[questionIndex];
    const categoryKey = findCategoryKey(question.category);
    const firstQuestionOfCategory = newTemplateData.category[categoryKey][0];
    const questionIndexInCategory = newTemplateData.category[
      categoryKey
    ].findIndex((question) => question.id === questionId);
    if (question.sequence > firstQuestionOfCategory.sequence) {
      newTemplateData.questions[questionIndex] =
        newTemplateData.questions[questionIndex - 1];
      newTemplateData.questions[questionIndex - 1] = question;
      newTemplateData.category[categoryKey][questionIndexInCategory] =
        newTemplateData.category[categoryKey][questionIndexInCategory - 1];
      newTemplateData.category[categoryKey][questionIndexInCategory - 1] =
        question;
      question.sequence--;
      newTemplateData.questions[questionIndex].sequence++;
    }
    setTemplateData(newTemplateData);
    setIsEditing(true);
  };

  const moveQuestionDown = (event, questionId) => {
    event.stopPropagation();
    const newTemplateData = { ...templateData };
    const questionIndex = newTemplateData.questions.findIndex(
      (question) => question.id === questionId
    );
    const question = newTemplateData.questions[questionIndex];
    const categoryKey = findCategoryKey(question.category);
    const lastQuestionOfCategory =
      newTemplateData.category[categoryKey].slice(-1)[0];
    const questionIndexInCategory = newTemplateData.category[
      categoryKey
    ].findIndex((question) => question.id === questionId);
    if (question.sequence < lastQuestionOfCategory.sequence) {
      newTemplateData.questions[questionIndex] =
        newTemplateData.questions[questionIndex + 1];
      newTemplateData.questions[questionIndex + 1] = question;
      newTemplateData.category[categoryKey][questionIndexInCategory] =
        newTemplateData.category[categoryKey][questionIndexInCategory + 1];
      newTemplateData.category[categoryKey][questionIndexInCategory + 1] =
        question;
      question.sequence++;
      newTemplateData.questions[questionIndex].sequence--;
    }
    setTemplateData(newTemplateData);
    setIsEditing(true);
  };

  const newOption = (questionId) => {
    const newTemplateData = { ...templateData };
    const question = newTemplateData.questions.find(
      (question) => question.id === questionId
    );
    const latestOption = question.options.slice(-1)[0];
    const newOption = {
      id: "__new__" + new Date().getTime(),
      option: "",
      explanation: "",
      score: 0,
      sequence: (latestOption?.sequence ?? 0) + 1,
    };
    question.options.push(newOption);

    setTemplateData(newTemplateData);
    setIsEditing(true);
  };

  const removeOption = (event, questionId, optionId) => {
    event.stopPropagation();
    const newTemplateData = { ...templateData };
    const questionIndex = newTemplateData.questions.findIndex(
      (question) => question.id === questionId
    );
    const question = newTemplateData.questions[questionIndex];
    question.options = question.options.filter(
      (option) => option.id !== optionId
    );
    // Reorder the options
    question.options = question.options.map((option, index) => {
      option.sequence = index + 1;
      return option;
    });
    newTemplateData.questions[questionIndex] = question;
    setTemplateData(newTemplateData);
    setIsEditing(true);
  };

  const moveOptionUp = (event, questionId, optionId) => {
    event.stopPropagation();
    const newTemplateData = { ...templateData };
    const questionIndex = newTemplateData.questions.findIndex(
      (question) => question.id === questionId
    );
    const question = newTemplateData.questions[questionIndex];
    const optionIndex = question.options.findIndex(
      (option) => option.id === optionId
    );
    const option = question.options[optionIndex];
    if (optionIndex > 0) {
      question.options[optionIndex] = question.options[optionIndex - 1];
      question.options[optionIndex - 1] = option;
      option.sequence--;
      question.options[optionIndex].sequence++;
    }
    setTemplateData(newTemplateData);
    setIsEditing(true);
  };

  const moveOptionDown = (event, questionId, optionId) => {
    event.stopPropagation();
    const newTemplateData = { ...templateData };
    const questionIndex = newTemplateData.questions.findIndex(
      (question) => question.id === questionId
    );
    const question = newTemplateData.questions[questionIndex];
    const optionIndex = question.options.findIndex(
      (option) => option.id === optionId
    );
    const option = question.options[optionIndex];
    if (optionIndex < question.options.length - 1) {
      question.options[optionIndex] = question.options[optionIndex + 1];
      question.options[optionIndex + 1] = option;
      option.sequence++;
      question.options[optionIndex].sequence--;
    }
    setTemplateData(newTemplateData);
    setIsEditing(true);
  };

  const handleAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const publishTemplate = () => {
    setIsLoading(true);

    dispatch(
      publishGlobalAssessmentTemplate({ objUser, templateId: template_id })
    )
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201) {
          showMsg("success", "Template has been published successfully");
          setTemplateStatus("Published");
          setIsLoading(false);
        } else {
          showMsg("error", "Error publishing template. Try again later");
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        showMsg("error", "Error publishing template. Try again later");
      });
  };

  const unPublishTemplate = () => {
    setIsLoading(true);

    dispatch(
      unPublishGlobalAssessmentTemplate({ objUser, templateId: template_id })
    )
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201) {
          showMsg("success", "Template has been unpublished");
          setTemplateStatus("Draft");
          setIsLoading(false);
        } else {
          showMsg("error", "Error unpublishing template. Try again later");
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        showMsg("error", "Error unpublishing template. Try again later");
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {isLoading || Object.keys(templateData).length === 0 ? (
        <LoadingSpinner />
      ) : (
        <MDBox p={2}>
          <div
            style={{
              position: "fixed",
              bottom: "40px",
              color: "#fff",
              padding: "1.2rem",
              transition: "0.7s ease all",
              backgroundColor: "rgba(0,0,0,0.8)",
              zIndex: 3,
              borderRadius: "10px",
              right:"30rem"
            }}
            className={isEditing ? "expanded" : "not-expanded"}
          >
            <b>
              You have unsaved changes. Please remember to save changes before
              leaving
            </b>
          </div>
          <MDTypography variant="h3">Edit Assessment Template</MDTypography>

          <form onSubmit={handleSubmit}>
            <MDInput
              name="name"
              label="Name"
              value={templateData.name}
              onChange={handleChange}
              variant="standard"
              fullWidth
            />
            <MDInput
              name="description"
              label="Description"
              value={templateData.description}
              onChange={handleChange}
              variant="standard"
              fullWidth
            />
            <Autocomplete
              disableClearable
              name="type"
              options={types}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label="Type"
                  name="type"
                  fullWidth
                />
              )}
              value={templateData.type}
              onChange={(e, value) =>
                handleChange({ target: { name: "type", value } })
              }
            />
            {editGlobal && (
              <Autocomplete
                disableClearable
                name="subscription_category"
                options={subscription_categories}
                getOptionLabel={(option) =>
                  option.charAt(0).toUpperCase() + option.slice(1)
                }
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="Subscription Category"
                    name="subscription_category"
                    fullWidth
                  />
                )}
                value={templateData.subscription_category}
                onChange={(e, value) =>
                  handleChange({
                    target: { name: "subscription_category", value },
                  })
                }
              />
            )}
            <MDTypography variant="h5" mt={2} mb={2}>
              Questions
            </MDTypography>
            <MDBox mb={5}>
              {Object.entries(templateData.category ?? []).map(
                ([categoryKey, questions]) => (
                  <Accordion
                    key={categoryKey}
                    expanded={expanded === categoryKey}
                    onChange={handleAccordion(categoryKey)}
                    TransitionProps={{ unmountOnExit: true }}
                    sx={{ backgroundColor: "action.background" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="icon" />}
                    >
                      <MDBox
                        display="flex"
                        gap=".6rem"
                        justifyContent="space-between"
                        alignItems="center"
                        flexGrow="1"
                      >
                        <MDBox
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Tooltip title="Move Up" placement="bottom">
                            <MDButton
                              variant="contained"
                              color="light"
                              iconOnly={true}
                              size="small"
                              onClick={(event) =>
                                moveCategoryUp(event, categoryKey)
                              }
                            >
                              <Icon>keyboard_arrow_up</Icon>
                            </MDButton>
                          </Tooltip>
                          <Tooltip title="Move Down" placement="bottom">
                            <MDButton
                              variant="contained"
                              color="light"
                              iconOnly={true}
                              size="small"
                              onClick={(event) =>
                                moveCategoryDown(event, categoryKey)
                              }
                            >
                              <Icon>keyboard_arrow_down</Icon>
                            </MDButton>
                          </Tooltip>
                          <Tooltip
                            title="Remove this category"
                            placement="bottom"
                          >
                            <MDButton
                              variant="contained"
                              color="error"
                              iconOnly={true}
                              size="small"
                              onClick={(event) =>
                                removeCategory(event, categoryKey)
                              }
                            >
                              <Icon>delete</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                        <MDInput
                          label="Category Name"
                          value={categories[categoryKey]}
                          onChange={(event) =>
                            handleCategoryChange(event, categoryKey)
                          }
                          onClick={(event) => event.stopPropagation()}
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                    </AccordionSummary>
                    <AccordionDetails>
                      <WVList>
                        {expanded === categoryKey &&
                          questions.map((question, questionIndex) => (
                            <QuestionDetails
                              key={question.id}
                              question={question}
                              questionIndex={questionIndex}
                              moveQuestionUp={moveQuestionUp}
                              moveQuestionDown={moveQuestionDown}
                              removeQuestion={removeQuestion}
                              handleQuestionChange={handleQuestionChange}
                              categoryKey={categoryKey}
                              scoreColors={scoreColors}
                              moveOptionDown={moveOptionDown}
                              moveOptionUp={moveOptionUp}
                              removeOption={removeOption}
                              handleOptionChange={handleOptionChange}
                              scores={scores}
                              newOption={newOption}
                            />
                          ))}
                      </WVList>
                    </AccordionDetails>
                    <AccordionActions>
                      <MDTypography
                        variant="button"
                        color="success"
                        onClick={() => newQuestion(categoryKey)}
                        sx={{ cursor: "pointer" }}
                      >
                        <MDBox
                          display="flex"
                          gap=".5rem"
                          alignItems="center"
                          color="success"
                          mt={1}
                          mb={1}
                        >
                          <Icon>add</Icon>
                          Add Question
                        </MDBox>
                      </MDTypography>
                    </AccordionActions>
                  </Accordion>
                )
              )}
              <MDTypography
                variant="button"
                color="success"
                onClick={newCategory}
                sx={{ cursor: "pointer" }}
              >
                <MDBox
                  display="flex"
                  gap=".5rem"
                  alignItems="center"
                  color="success"
                  mt={1}
                  mb={1}
                >
                  <Icon>add</Icon>
                  Add Category
                </MDBox>
              </MDTypography>
            </MDBox>

            {isSaving ? (
              <LoadingSpinner />
            ) : (
              <>
                {!editGlobal ? (
                  <>
                    <MDButton
                      type="submit"
                      color="dark"
                      variant="gradient"
                      onClick={handleSubmit}
                      disabled={
                        !!templateData.is_global_template && !editGlobal
                      }
                    >
                      Save Changes
                    </MDButton>
                    <MDButton
                      type="submit"
                      // variant="gradient"
                      color="info"
                      onClick={() => setIsReturn(true)}
                      sx={{ padding: "12px 12px", marginLeft: "10px" }}
                    >
                      SAVE AND RETURN TO TEMPLATES
                    </MDButton>
                  </>
                ) : (
                  <MDBox
                    pr={5}
                    sx={{ margin: "0" }}
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                  >
                    {status === "Published" && (
                      <MDButton
                        variant="gradient"
                        color="primary"
                        onClick={unPublishTemplate}
                        sx={{ padding: "12px 12px" }}
                      >
                        UNPUBLISH TEMPLATE
                      </MDButton>
                    )}
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      onClick={handleSubmit}
                      sx={{ padding: "12px 12px", marginLeft: "1rem" }}
                    >
                      SAVE CHANGES
                    </MDButton>
                    <MDButton
                      type="submit"
                      // variant="gradient"
                      color="info"
                      onClick={() => setIsReturn(true)}
                      sx={{ padding: "12px 12px", marginLeft: "10px" }}
                    >
                      SAVE AND RETURN TO TEMPLATES
                    </MDButton>
                    {status === "Draft" && (
                      <MDButton
                        color="success"
                        onClick={publishTemplate}
                        sx={{ padding: "12px 12px", marginLeft: "1rem" }}
                      >
                        PUBLISH TEMPLATE
                      </MDButton>
                    )}
                  </MDBox>
                )}
              </>
            )}
          </form>
        </MDBox>
      )}
    </DashboardLayout>
  );
};

EditAssessmentTemplates.defaultProps = {
  backUrl: "/assessments/templates/",
  editGlobal: false,
};

export default EditAssessmentTemplates;

// react-router-dom components

// @mui material components

import { useEffect, useRef, useState } from "react";

// formik components
import { Form, Formik } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// Authentication layout components
// Other authentication methods components
// Images
import { unwrapResult } from "@reduxjs/toolkit";
import { saveCompanyTemplateChanges } from "features/company/templatesActions";
import { useDispatch } from "react-redux";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { usePrompt } from "utils/Helpers/NavigationBlocker";
import { showMsg } from "utils/general";
import AgreeToTermsDialog from "../components/AgreeToTermsDialog";
import CloneTemplateDialog from "../components/CloneTemplateDialog";
import EditTemplateFormikWrapper from "./EditTemplateFormikWrapper";
// import "./CreatePolicyDocumentForm.scss";
// import usePDFDownloader from "./usePDFDownloader";
import useTemplatePreviewer from "./useTemplatePreviewer";
import PreviewTemplateDialog from "../components/PreviewTemplateDialog";

const EditTemplateDraft = (props) => {
  const { objUser, objTemplate, loadTemplate } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { templateId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isReturn, setIsReturn] = useState(false);
  const [documentSectionsToSave, setDocumentSectionsToSave] = useState([]);
  const [editorDocument, setEditorDocument] = useState("");
  const [objInitialValues, setObjInitialValues] = useState({
    template_name: "",
    created_by: "",
    document: "",
    is_global_template: "",
    template_sections: [],
  });

  const [objAddNewCustomerFormErrors, setObjAddNewCustomerFormErrors] =
    useState({});
  const [openCloneTemplateDialog, setOpenCloneTemplateDialog] = useState(false);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [showReferenceMaterial, setShowReferenceMaterial] = useState(false);

  const [isExpired, setIsExpired] = useState(false);
  const [isClientUser, setIsClientUser] = useState(false);
  const [openTermsDialog, setOpenTermsDialog] = useState(false);

  const [savingSectionIndex, setSavingSectionIndex] = useState(null);

  const { previewTemplate, isLoadingPreview, templateObj, setTemplateObj } =
    useTemplatePreviewer();
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const editorRef = useRef(null);
  const formRef = useRef();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  useEffect(() => {
    if (
      objUser.user.role.name != "admin" &&
      objUser.user.role.name != "company_user"
    ) {
      setIsClientUser(true);
    }
    var tags = [];

    if (objTemplate.tags) {
      tags = objTemplate.tags.map((tg) => tg.name);
    }

    let sorted_rmfs = objTemplate.risk_management_frameworks ?? [];

    if (sorted_rmfs.length > 0) {
      sorted_rmfs = objTemplate.risk_management_frameworks.sort((a, b) => {
        const nameA = a.owning_rmf.name.toLowerCase();
        const nameB = b.owning_rmf.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }

    let document_sections = objTemplate.document_sections ?? [];
    if (document_sections.length > 0) {
      document_sections = document_sections.sort(
        (a, b) => a.owning_global_section.order - b.owning_global_section.order
      );
    }

    if (objTemplate?.is_global_template === 1) {
      setShowReferenceMaterial(true);
    } else if (
      objTemplate?.is_global_template === 0 &&
      objTemplate?.owning_global_template !== null
    ) {
      setShowReferenceMaterial(true);
    }

    const __ObjInitialValues = {
      template_name: objTemplate.name ?? "",
      created_by: objTemplate.created_by ?? "",
      description: objTemplate.description ?? "",
      notes: objTemplate.notes ?? "",
      document: objTemplate?.document ?? "",
      is_global_template: objTemplate?.is_global_template ?? 0,
      tags: tags,
      risk_management_frameworks: sorted_rmfs,
      template_sections: document_sections,
    };

    if (
      typeof objTemplate.id !== "undefined" &&
      objTemplate.id !== null &&
      typeof objTemplate.document !== "undefined"
    ) {
      __ObjInitialValues.document = objTemplate.document;
    }
    setObjInitialValues(__ObjInitialValues);
    setDocumentSectionsToSave(__ObjInitialValues.template_sections ?? []);
    setEditorDocument(__ObjInitialValues.document);
  }, [objTemplate]);

  const handleUpdateDocumentSectionsToSave = (field, index, newValue) => {
    let updatedSections = [...documentSectionsToSave];
    updatedSections[index][field] = newValue;
    setDocumentSectionsToSave(updatedSections);
  };

  const btnClickSectionSave = async (index, successCallback) => {
    setSavingSectionIndex(index);
    const objPostData = {
      name: formRef.current.values.template_name,
      tags: formRef.current.values.templateTags,
      description: formRef.current.values.description,
      notes: formRef.current.values.notes,
      rmfs: formRef.current.values.risk_management_frameworks,
      document: editorRef.current ? editorRef.current.getContent() : "-",
      updatedDocumentSections: [documentSectionsToSave[index]],
    };
    try {
      const response = await dispatch(
        saveCompanyTemplateChanges({ objPostData, objUser, templateId })
      ).unwrap();
      if (response.status === 201) {
        showMsg("success", "Section saved succesfully");
        successCallback();
      }
    } catch (e) {
      showMsg("error", "Error saving template. Try again later");
    } finally {
      setSavingSectionIndex(null);
    }
  };

  const onSubmitForm = (currentValues) => {
    setIsLoading(true);
    handleAutoSave(
      editorRef.current,
      () => showMsg("success", "Template saved succesfully"),
      () => showMsg("error", "Error saving template. Try again later")
    );
  };

  const handleAutoSave = (editor, successCallback, failureCallback) => {
    const objPostData = {
      name: formRef.current.values.template_name,
      tags: formRef.current.values.templateTags,
      description: formRef.current.values.description,
      notes: formRef.current.values.notes,
      rmfs: formRef.current.values.risk_management_frameworks,
      document: editor ? editor.getContent() : "-",
      updatedDocumentSections: [...documentSectionsToSave],
    };

    dispatch(saveCompanyTemplateChanges({ objPostData, objUser, templateId }))
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201) {
          successCallback();
          setObjInitialValues(formRef.current.values);
          setIsLoading(false);
          if (isReturn) {
            navigate(`/template-library`, {
              state: { routeDictionary },
            });
          }
          // setDocumentVersions([...documentVersions, response.data]);
        } else {
          failureCallback();
          setIsLoading(false);
        }
      })
      .catch(() => {
        failureCallback();
        setIsLoading(false);
      });
  };

  usePrompt(
    "There are unsaved changes. Are you sure you want to leave?",
    (formRef.current?.values?.template_name !==
      objInitialValues.template_name ||
      JSON.stringify(formRef.current?.values?.tags) !==
        JSON.stringify(objInitialValues.tags) ||
      editorRef.current?.isDirty()) &&
      objUser.user.role.name == "admin"
  );

  const onSubmitCloneTemplateDialog = (templateId) => {
    setOpenCloneTemplateDialog(false);
    // window.location.reload();
    navigate(`/template-library/${templateId}`, {
      state: { routeDictionary },
    });
    window.location.reload();
  };

  const btnOnClickCloneTemplate = () => {
    setOpenCloneTemplateDialog(true);
  };

  const onClosCloneTemplateDialog = () => {
    setOpenCloneTemplateDialog(false);
  };

  const btnOnClickOpenTermsDialog = () => {
    setOpenTermsDialog(true);
  };

  const onCloseTermsDialog = () => {
    setOpenTermsDialog(false);
  };

  const onClosePreviewDialog = () => {
    setOpenPreviewDialog(false);
    setTemplateObj(null);
  };

  // const btnClickDownloadPDF = () => {
  //   downloadPDF(objUser, objTemplate?.id);
  // };

  const btnClickPreviewTemplate = () => {
    previewTemplate(objUser, objTemplate?.id);
  };


  // autosave after every thirty seconds
  const autoSave = () => {
    setInterval(() => {
      handleAutoSave(
        editorRef.current,
        () => {
        },
        () => {
        }
      );
    }, 30000); 
  };

  useEffect(() => {
    autoSave();
  }, []);

  return (
    <>
      <PreviewTemplateDialog
        //open={openPreviewDialog}
        open={templateObj !== null}
        onClose={onClosePreviewDialog}
        objTemplate={templateObj?.template}
        showReferenceMaterial={templateObj?.showReferenceMaterial}
      />
      <AgreeToTermsDialog
        open={openTermsDialog}
        onClose={onCloseTermsDialog}
        openCloneTemplateDialog={btnOnClickCloneTemplate}
        objTemplate={objTemplate}
        objUser={props.objUser}
        onSubmit={onSubmitCloneTemplateDialog}
      />
      <CloneTemplateDialog
        open={openCloneTemplateDialog}
        onClose={onClosCloneTemplateDialog}
        objTemplate={objTemplate}
        onSubmit={onSubmitCloneTemplateDialog}
        objUser={props.objUser}
      />
      {isLoadingTemplate === false &&
      Object.keys(objInitialValues).length > 0 &&
      objInitialValues.template_name !== "" ? (
        <Formik
          initialValues={objInitialValues}
          innerRef={formRef}
          onSubmit={onSubmitForm}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id={"EditTemplateForm"} autoComplete="off">
              <MDBox>
                <EditTemplateFormikWrapper
                  isClientUser={isClientUser}
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  objStates={{}}
                  objTemplate={objTemplate}
                  documentSectionsToSave={documentSectionsToSave}
                  editorDocument={editorDocument}
                  setEditorDocument={setEditorDocument}
                  showReferenceMaterial={showReferenceMaterial}
                  handleUpdateDocumentSectionsToSave={
                    handleUpdateDocumentSectionsToSave
                  }
                  handleAutoSave={handleAutoSave}
                  savingSectionIndex={savingSectionIndex}
                  btnClickSectionSave={btnClickSectionSave}
                  editorRef={editorRef}
                  mergeTags={objTemplate.merge_tags}
                  // categories={objTemplate.policy.extra.categories}
                  // fetchDocumentVersion={fetchDocumentVersion}
                />
                {!isClientUser ? (
                  <>
                    {isLoading === false && isLoadingPreview === false ? (
                      <MDBox
                        px={5}
                        sx={{ margin: "0" }}
                        width="100%"
                        display="flex"
                        justifyContent="flex-start"
                      >
                        {objTemplate.is_global_template == 1 ? (
                          <MDButton
                            variant="gradient"
                            color="dark"
                            // onClick={btnOnClickCloneTemplate}
                            onClick={btnOnClickOpenTermsDialog}
                            sx={{ padding: "12px 12px" }}
                          >
                            CLONE TEMPLATE
                          </MDButton>
                        ) : (
                          <>
                            <MDButton
                              onClick={() => btnClickPreviewTemplate()}
                              variant="gradient"
                              color="primary"
                              sx={{ padding: "12px 12px" }}
                            >
                              PREVIEW DOCUMENT
                            </MDButton>
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="dark"
                              sx={{ padding: "12px 12px", marginLeft: "10px" }}
                            >
                              SAVE CHANGES
                            </MDButton>

                            <MDButton
                              type="submit"
                              // variant="gradient"
                              color="info"
                              onClick={() => setIsReturn(true)}
                              sx={{ padding: "12px 12px", marginLeft: "10px" }}
                            >
                              SAVE AND RETURN TO DOC TEMPLATE LIBRARY
                            </MDButton>
                          </>
                        )}
                      </MDBox>
                    ) : (
                      <MDBox
                        px={5}
                        sx={{ margin: "0" }}
                        width="100%"
                        display="flex"
                        justifyContent="flex-start"
                      >
                        <LoadingSpinner color="success" size="lg" />
                      </MDBox>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </MDBox>
            </Form>
          )}
        </Formik>
      ) : (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      )}
    </>
  );
};

export default EditTemplateDraft;

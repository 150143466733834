import React, { useEffect, useState } from "react";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Card } from "@mui/material";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import PostGroupResource from "./components/PostGroupResource";
import { fetchPeerGroupPost } from "features/company/peerGroupActions.js";
import DashboardActionButton from "views/components/DashboardActionButton";
import PeerGroupScheduleDialog from "./components/PeerGroupScheduleDialog";
import { displayExceptionErrorMessage } from "utils/general";

const PeerGroup = ({ objUser }) => {
  const dispatch = useDispatch();
  const [allPeergroupPosts, setPeerGroupPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenScheduleDialog, setIsOpenScheduleDialog] = useState(false);
  const [selectedTab, setSelectedTab] = useState("paid");

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== selectedTab}
        id={`simple-tabpanel-${selectedTab}`}
        aria-labelledby={`simple-tab-${selectedTab}`}
        {...other}
      >
        {value === selectedTab && <div>{children}</div>}
      </div>
    );
  };

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };

  const fetchPosts = () => {
    setIsLoading(true);
    dispatch(fetchPeerGroupPost())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setIsLoading(false);
        if (response.status === 200) {
          setPeerGroupPosts(response.data);
  
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      })
      .finally(() => {});
  };


  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Card
              position="relative"
              sx={{ padding: "32px", marginBottom: "1rem", minHeight: "80vh" }}
            >
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Grid container>
                    <Grid item xs={6}>
                      <MDBox mb={3} textAlign="left">
                        <MDTypography variant="h3">
                          Peer Group Resources
                        </MDTypography>
                      </MDBox>
                    </Grid>

                    <Grid item xs={6}>
                      <MDBox textAlign="right" sx={{ marginTop: "-13px" }}>
                        <DashboardActionButton
                          action={() => {
                            setIsOpenScheduleDialog(true);
                          }}
                          btnText="Peer Group Schedule"
                          btnIcon="schedule"
                          textColor="white"
                          bgColor="success"
                          sx={{ marginTop: "0px !important" }}
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Tabs
                    value={selectedTab}
                    onChange={(event, newValue) => handleTabChange(newValue)}
                    centered
                  >
                    <Tab value={"paid"} label="Paid Peer Group Content" />
                    <Tab value={"free"} label="Free Peer Group Content" />
                  </Tabs>
                  <TabPanel value={"free"}>
                    {allPeergroupPosts.free_peergroup_posts.length > 0 ? (
                      <Grid container spacing={2}>
                        {allPeergroupPosts.free_peergroup_posts.map((post) => (
                          <Grid item xs={4} mt={4} key={post.id}>
                            <PostGroupResource post={post} />
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <div>Nothing to show</div>
                    )}
                  </TabPanel>
                  <TabPanel value={"paid"}>
                    {allPeergroupPosts.paid_peergroup_posts.length > 0 ? (
                      <Grid container spacing={2}>
                        {allPeergroupPosts.paid_peergroup_posts.map((post) => (
                          <Grid item xs={4} mt={4} key={post.id}>
                            <PostGroupResource post={post} />
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <div>Nothing to show</div>
                    )}
                  </TabPanel>
                </>
              )}
            </Card>
          </Grid>
          <PeerGroupScheduleDialog
            open={isOpenScheduleDialog}
            onClose={() => {
              setIsOpenScheduleDialog(false);
            }}
          />
        </Grid>
    </DashboardLayout>
  );
};

export default PeerGroup;

import { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";

import { unwrapResult } from "@reduxjs/toolkit";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardActionButton from "views/components/DashboardActionButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import {
  getAssessmentEventsForReports,
  fetchAssessmentEvent,
  getAssessmentEventVersions,
  getAssessmentReportPDF,
} from "features/company/assessmentsActions";
import { getAssessmentItemScoring } from "features/assessmentConfig/assessmentConfigActions";
import { fetchReportLogo } from "features/company/CompanyConfigActions";
import AddToRiskMatrixDialog from "./components/AssessmentEventsTable/addToRiskMatrixDialog";
import { addRiskRegisterItem } from "features/company/riskRegisterActions.js";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { showMsg } from "utils/general";

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { Grid, Select, MenuItem, Checkbox, FormLabel } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import tinycolor from "tinycolor2";
import "./reports.css";
import DOMPurify from "dompurify";
import EditRiskMatrixDialog from "./components/AssessmentEventsTable/editRiskMatrixDialog";
import RiskMatrixItemDeleteBtnTr from "../AssessmentEvents/components/AssessmentEventsTable/RiskMatrixItemDeleteBtnTr";
import RiskMatrixItemEditBtnTr from "../AssessmentEvents/components/AssessmentEventsTable/RiskMatrixItemEditBtnTr";
import { bulkAddToRiskRegister } from "features/company/riskRegisterActions";
import { displayExceptionErrorMessage } from "utils/general";

const AssessmentReports = (props) => {
  const { objUser } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingPDF, setIsFetchingPDF] = useState(false);
  const [isLoadingAssessment, setIsLoadingAssessment] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [currentAssessment, setCurrentAssessment] = useState("");
  const [currentAssessmentData, setCurrentAssessmentData] = useState("");
  const [versionsForSelectedTemplate, setVersionsForSelectedTemplate] =
    useState([]);
  const [defaultSelectedVersion, setDefaultSelectedVersion] = useState("");
  const [itemId, setItemId] = useState("");
  const [itemScores, setItemScores] = useState({});
  const [userList, setUserList] = useState([]);
  const [riskMatrixes, setRiskMatrixes] = useState([]);
  const [riskRegisters, setRiskRegisters] = useState([]);
  const [completedEventsOnlychecked, setCompletedEventsOnlychecked] =
    useState(true);
  const [openNewRiskMatrixDialog, setOpenNewRiskMatrixDialog] = useState(false);
  const [openEditRiskMatrixDialog, setOpenEditRiskMatrixDialog] =
    useState(false);

  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();

  const [colorOptions, setColorOptions] = useState([]);

  const [reportLogoURL, setReportLogoURL] = useState(null);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const ResponsiveFlexContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  }));

  const sortQuestionsIntoCategories = (objTemplate) => {
    objTemplate.category = {};
    objTemplate.questions.sort((a, b) => a.sequence - b.sequence);
    for (let index = 0; index < objTemplate.questions.length; index++) {
      const element = objTemplate.questions[index];
      element.options.sort((a, b) => a.sequence - b.sequence);
      if (typeof objTemplate.category[element.category] === "undefined") {
        objTemplate.category[element.category] = [];
      }

      objTemplate.category[element.category].push(element);
    }

    return objTemplate;
  };

  const lightenColor = (color) => {
    const colorObject = colorOptions.find((item) => {
      return item.square_color === color;
    });
    if (colorObject != undefined && colorObject) {
      return colorObject.row_color;
    } else {
      const colorObj = tinycolor(color);
      return colorObj.isDark()
        ? colorObj.brighten(60).toString()
        : colorObj.darken(5).toString();
    }
  };

  const handleChange = (value) => {
    setSelectedQuestions([]);
    setCurrentAssessment(value);
    loadTemplateVersions(value.assessment_template_id);
  };

  const handleVersionChange = (value) => {
    setSelectedQuestions([]);
    setDefaultSelectedVersion(value);
    fetchAssessment(value.id);
  };

  const handleCompletedEventsOnlyCheckboxChange = () => {
    setSelectedQuestions([]);
    setCompletedEventsOnlychecked(!completedEventsOnlychecked);
  };

  useEffect(() => {
    loadTemplateVersions(currentAssessment.assessment_template_id);
  }, [completedEventsOnlychecked]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const loadReportLogo = async () => {
    dispatch(fetchReportLogo({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.data.report_logo) {
          setReportLogoURL(response.data.report_logo);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong as we tried to fetch your reports logo, please try again."
        );
      });
  };

  const loadTemplates = () => {
    setIsLoading(true);
    dispatch(getAssessmentEventsForReports({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.data.events.length > 0) {
          if (response.data.events.length > 0) {
            setAssessments(response.data.events);

            setCurrentAssessment(response.data.events[0]);
            loadTemplateVersions(
              response.data.events[0].assessment_template_id
            );

            setTotalCount(response.data.templates?.length);
          } else {
            setTotalCount(0);
            setIsLoading(false);
          }
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      })
      .finally(() => {});
  };

  const loadTemplateVersions = (assessment_template_id) => {
    setIsLoading(true);
    dispatch(
      getAssessmentEventVersions({
        objUser,
        assessment_template_id,
        completedEventsOnlychecked,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        const response = originalPromiseResult;
        if (response.data.events_obj.events.length > 0) {
          setVersionsForSelectedTemplate(response.data.events_obj.events);
          setDefaultSelectedVersion(response.data.events_obj.events[0]);
          setTotalCount(response.data.events_obj.events.length);
          fetchAssessment(response.data.events_obj.events[0].id);
        } else {
          setVersionsForSelectedTemplate([]);
          setDefaultSelectedVersion("");
          setTotalCount(0);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchAssessment = (eventId) => {
    setIsLoadingAssessment(true);
    dispatch(fetchAssessmentEvent({ objUser, eventId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        const objTemplate = sortQuestionsIntoCategories(response.data?.event);
        const scores = {};
        response.data?.itemScores?.forEach((itemScore) => {
          const color = tinycolor(itemScore.color);
          scores[itemScore.code] = {
            color: color.toString(),
            text_color: itemScore.text_color,
            name: itemScore.name,
            value: itemScore.value,
            possible_points: itemScore.possible_points,
            percentage: 0,
          };
        });
        response.data?.event.questions.map((question) => {
          const selectedOption = question.options.find(
            (option) => option.id === question.answer
          );
          if (selectedOption) {
            scores[selectedOption.score].percentage =
              parseInt(scores[selectedOption.score].percentage) + 1;

            if (scores[selectedOption.score][question.category]) {
              scores[selectedOption.score][question.category] =
                scores[selectedOption.score][question.category] + 1;
            } else {
              scores[selectedOption.score][question.category] = 1;
            }
          }
        });
        objTemplate.riskMatrixItems = response.data?.riskMatrixItems;
        objTemplate.riskRegisterItems = response.data?.riskRegisterItems;
        let riskMatrixes = response.data.riskMatrixItems.map((item) => {
          return item.item_id;
        });
        setRiskMatrixes(riskMatrixes);
        let riskRegisters = response.data.riskRegisterItems.map((item) => {
          return item.assessment_question_id;
        });
        setRiskRegisters(riskRegisters);
        setUserList([
          { id: "", name: "Not Applicable" },
          ...response.data?.userList,
        ]);
        setItemScores(scores);
        setCurrentAssessmentData(objTemplate);
      })
      .catch((rejectedValueOrSerializedError) => {
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        if (objErrorRsp.status === 403) {
          showMsg("error", "You are not authorized to view this assessment");
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
        }
      })
      .finally(() => {
        fetchCurrentScoring();
      });
  };

  const fetchCurrentScoring = () => {
    dispatch(getAssessmentItemScoring({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const results = originalPromiseResult.data.assessment_item_scoring;
        setColorOptions(originalPromiseResult.data.color_options);
        setIsLoadingAssessment(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingAssessment(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const onNewItem = () => {
    setOpenNewRiskMatrixDialog(false);
    setOpenEditRiskMatrixDialog(false);
    setSelectedQuestions([]);
    fetchAssessment(currentAssessmentData.id);
  };

  const onDelete = () => {
    fetchAssessment(currentAssessmentData.id);
  };

  const openNewRiskMatrixDialogHandler = (id) => {
    setItemId(id);
    setOpenNewRiskMatrixDialog(true);
  };

  const openEditMatrixDialogHandler = (id) => {
    setItemId(id);
    setOpenEditRiskMatrixDialog(true);
  };

  const addToRiskRegister = (itemId) => {
    setIsLoadingAssessment(true);

    const objPostData = {
      itemId,
    };

    let eventId = currentAssessmentData.id;

    dispatch(addRiskRegisterItem({ objPostData, objUser, eventId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        fetchAssessment(currentAssessmentData.id);
        setSelectedQuestions([]);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingAssessment(false);
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized");
        } 
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      })
      .finally(() => {
        setIsLoadingAssessment(false);
      });
  };

  const bulkAddRiskRegisterItems = () => {
    setIsLoadingAssessment(true);
    let questionIds = selectedQuestions;
    const objPostData = {
      questionIds,
    };

    let eventId = currentAssessmentData.id;

    dispatch(bulkAddToRiskRegister({ objPostData, objUser, eventId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        fetchAssessment(currentAssessmentData.id);
        setSelectedQuestions([]);
        showMsg("success", "Items added to Risk Register Successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingAssessment(false);
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.status === 401) {
          showMsg("error", "User is not authorized");
        } else {
          displayExceptionErrorMessage(rejectedValueOrSerializedError);
        }
      })
      .finally(() => {
        setIsLoadingAssessment(false);
      });
  };

  const printPage = () => {
    setIsFetchingPDF(true);
    let objData = currentAssessmentData;
    objData.itemScores = itemScores;
    switch (tab) {
      case 0:
        objData.section = "Item Analysis";
        break;
      case 1:
        objData.section = "Overall Performance";
        break;
      case 2:
        objData.section = "Category Performance";
        break;
      case 3:
        objData.section = "Risk Matrix";
        break;
      default:
        objData.section = "Item Analysis";
    }
    dispatch(
      getAssessmentReportPDF({
        objUser,
        objData,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingPDF(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${currentAssessmentData.name}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // TODO handle download error
        setIsFetchingPDF(false);

        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.message
        );
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.error_code
        );
      });
  };

  let eventId = currentAssessmentData.id;
  const sanitizedExplanation = (explanation) => {
    return DOMPurify.sanitize(explanation ?? "");
  };

  const handleCheckBoxChange = (id) => {
    if (selectedQuestions.includes(id)) {
      setSelectedQuestions(selectedQuestions.filter((item) => item !== id));
    } else {
      setSelectedQuestions([...selectedQuestions, id]);
    }
  };

  const handleSelectAllCheckBoxChange = (e) => {
    if (e.target.checked) {
      let questions = [];
      currentAssessmentData.questions.map((question) => {
        question.options.map((option) => {
          if (
            option.id == question.answer &&
            itemScores[option?.score].name !== "Satisfactory" &&
            (!riskMatrixes.includes(question.id) ||
              !riskRegisters.includes(question.id))
          ) {
            questions.push(question.id);
          }
        });
      });
      setSelectedQuestions(questions);
    } else {
      setSelectedQuestions([]);
    }
  };

  useEffect(() => {
    loadTemplates();
    loadReportLogo();
  }, []);

  return (
    <>
      <MDBox className="no-print">
        <DashboardLayout>
          <MDBox className="no-print">
            <DashboardNavbar />
          </MDBox>
          <AddToRiskMatrixDialog
            objUser={objUser}
            open={openNewRiskMatrixDialog}
            onClose={() => setOpenNewRiskMatrixDialog(false)}
            onSubmit={onNewItem}
            eventId={currentAssessmentData.id}
            itemId={itemId}
            selectedQuestions={selectedQuestions}
          />
          {isLoading ? (
            <Card>
              <LoadingSpinner color="success" size="lg" />
            </Card>
          ) : (
            <>
              <MDBox>
                <Grid
                  spacing={2}
                  container
                  width="100%"
                  sx={{ alignItems: "flex-start" }}
                >
                  <Grid item xs={12} lg={12}>
                    {assessments.length > 0 && (
                      <MDBox
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"baseline"}
                      >
                        <MDBox pb={4} display={"flex"} alignItems={"baseline"}>
                          <MDTypography variant="body2" fontWeight={"bold"}>
                            Select an Assessment Event:
                          </MDTypography>

                          {/* select template */}
                          <Select
                            value={currentAssessment}
                            // p={1}
                            sx={{
                              minWidth: "150px",
                              marginLeft: "10px",
                              backgroundColor: "white",
                              padding: "8px",
                              borderWidth: "4px",
                            }}
                            onChange={(e) => handleChange(e.target.value)}
                          >
                            <MenuItem value="" disabled>
                              Select an Event
                            </MenuItem>
                            {assessments.map((event) => (
                              <MenuItem key={event.id} value={event}>
                                {event.assessment_template.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <UnfoldMoreIcon
                            sx={{
                              position: "relative",
                              right: "20px",
                              top: "3.5px",
                              pointerEvents: "none",
                            }}
                          />

                          {/* select version */}
                          <Select
                            value={defaultSelectedVersion}
                            sx={{
                              minWidth: "150px",
                              marginLeft: "10px",
                              backgroundColor: "white",
                              padding: "8px",
                              borderWidth: "4px",
                            }}
                            onChange={(e) =>
                              handleVersionChange(e.target.value)
                            }
                          >
                            <MenuItem value="" disabled>
                              Select a version
                            </MenuItem>
                            {versionsForSelectedTemplate.map((event) => (
                              <MenuItem key={event.id} value={event}>
                                v{event.version}.0{" "}
                                {event?.status?.replace("_", " ")}
                              </MenuItem>
                            ))}
                          </Select>
                          <UnfoldMoreIcon
                            sx={{
                              position: "relative",
                              right: "20px",
                              top: "3.5px",
                              pointerEvents: "none",
                            }}
                          />

                          {/* check to show only completed versions */}
                          <FormLabel
                            sx={{ fontSize: "1rem", cursor: "pointer" }}
                          >
                            <Checkbox
                              sx={{ transform: "translateY(-1px)" }}
                              checked={completedEventsOnlychecked}
                              onChange={() =>
                                handleCompletedEventsOnlyCheckboxChange()
                              }
                              icon={
                                <CheckBoxOutlineBlankOutlinedIcon
                                  style={{ borderColor: "#0b2e4a" }}
                                />
                              }
                            />
                            Completed events only
                          </FormLabel>
                        </MDBox>
                        {isFetchingPDF ? (
                          <LoadingSpinner
                            subClass="text-center"
                            color="success"
                            size="sm"
                          />
                        ) : (
                          <DashboardActionButton
                            action={printPage}
                            btnText="Export to PDF"
                            btnIcon="print"
                            textColor="white"
                            bgColor="success"
                            sx={{ marginTop: "0px !important" }}
                          />
                        )}
                      </MDBox>
                    )}

                    {totalCount > 0 ? (
                      <>
                        <MDBox px={4}>
                          <Tabs value={tab} onChange={handleTabChange} centered>
                            <Tab label="Item Analysis" />
                            <Tab label="Overall Performance" />
                            <Tab label="Category Performance" />
                            <Tab label="Risk Matrix Report" />
                          </Tabs>
                        </MDBox>

                        {tab === 0 && (
                          <MDBox pt={4}>
                            <MDBox display="flex">
                              <MDTypography variant="h5" fontWeight="bold">
                                Item Analysis
                              </MDTypography>
                            </MDBox>

                            <Card sx={{ marginTop: "16px" }}>
                              <TableContainer sx={{ padding: "1rem" }}>
                                {isLoadingAssessment ? (
                                  <LoadingSpinner color="success" size="lg" />
                                ) : (
                                  <Table sx={{ color: "text.main" }}>
                                    <TableRow>
                                      <TableCell align="center">
                                        <Checkbox
                                          edge="start"
                                          onChange={
                                            handleSelectAllCheckBoxChange
                                          }
                                          tabIndex={-1}
                                          disableRipple
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        <b>Item Name</b>
                                      </TableCell>
                                      <TableCell align="center">
                                        <b>Risk Level</b>
                                      </TableCell>
                                      <TableCell align="center">
                                        <b>Add to Risk Matrix</b>
                                      </TableCell>
                                      <TableCell align="center">
                                        <b>Add to Risk Register</b>
                                      </TableCell>
                                    </TableRow>

                                    <TableBody>
                                      {Object.entries(
                                        currentAssessmentData.category ?? []
                                      ).map(
                                        ([category, questions], catIndex) => (
                                          <Fragment key={category}>
                                            {questions.map(
                                              (question, index) => {
                                                const selectedOption =
                                                  question.options.find(
                                                    (option) =>
                                                      option.id ===
                                                      question.answer
                                                  );
                                                return (
                                                  <StyledTableRow key={index}>
                                                    <StyledTableCell align="center">
                                                      {question.answer && (
                                                        <>
                                                          {question.id &&
                                                            itemScores[
                                                              selectedOption
                                                                ?.score
                                                            ]?.name !==
                                                              "Satisfactory" &&
                                                            (!riskRegisters.includes(
                                                              question.id
                                                            ) ||
                                                              !riskMatrixes.includes(
                                                                question.id
                                                              )) && (
                                                              <Checkbox
                                                                edge="start"
                                                                checked={selectedQuestions.includes(
                                                                  question?.id
                                                                )}
                                                                onChange={() => {
                                                                  handleCheckBoxChange(
                                                                    question?.id
                                                                  );
                                                                }}
                                                                tabIndex={-1}
                                                                disableRipple
                                                              />
                                                            )}
                                                        </>
                                                      )}
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                      component="th"
                                                      scope="row"
                                                      align="center"
                                                      sx={{
                                                        color: "text.main",
                                                      }}
                                                    >
                                                      {question.item}
                                                    </StyledTableCell>
                                                    {question.answer ? (
                                                      <TableCell
                                                        align="center"
                                                        sx={{
                                                          "&:last-child td, &:last-child th":
                                                            {
                                                              border: 0,
                                                            },
                                                          backgroundColor:
                                                            lightenColor(
                                                              itemScores[
                                                                selectedOption
                                                                  .score
                                                              ].color
                                                            ),
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {
                                                          itemScores[
                                                            selectedOption
                                                              ?.score
                                                          ].name
                                                        }
                                                      </TableCell>
                                                    ) : (
                                                      <TableCell
                                                        align="center"
                                                        sx={{
                                                          "&:last-child td, &:last-child th":
                                                            {
                                                              border: 0,
                                                            },
                                                          width: "30%",
                                                          color: "text.main",
                                                        }}
                                                      >
                                                        Item not Completed
                                                      </TableCell>
                                                    )}
                                                    <StyledTableCell
                                                      component="th"
                                                      scope="row"
                                                      align="center"
                                                      sx={{
                                                        color: "text.main",
                                                      }}
                                                    >
                                                      {question.answer && (
                                                        <>
                                                          {question.id &&
                                                            itemScores[
                                                              selectedOption
                                                                ?.score
                                                            ].name !==
                                                              "Satisfactory" &&
                                                            !riskMatrixes.includes(
                                                              question.id
                                                            ) && (
                                                              <MDBox display="flex">
                                                                <MDButton
                                                                  onClick={() =>
                                                                    openNewRiskMatrixDialogHandler(
                                                                      question?.id
                                                                    )
                                                                  }
                                                                >
                                                                  <MDBox
                                                                    color="dark"
                                                                    display="flex"
                                                                    alignItems="center"
                                                                  >
                                                                    <Icon
                                                                      sx={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      add
                                                                    </Icon>
                                                                    <MDTypography
                                                                      variant="p"
                                                                      fontWeight="medium"
                                                                      color="text.main"
                                                                      sx={{
                                                                        lineHeight: 0,
                                                                        marginLeft:
                                                                          "0.5rem",
                                                                      }}
                                                                    >
                                                                      Add
                                                                    </MDTypography>
                                                                  </MDBox>
                                                                </MDButton>
                                                              </MDBox>
                                                            )}

                                                          {question.id &&
                                                            itemScores[
                                                              selectedOption
                                                                ?.score
                                                            ].name !==
                                                              "Satisfactory" &&
                                                            riskMatrixes.includes(
                                                              question.id
                                                            ) && (
                                                              <>Already Added</>
                                                            )}
                                                        </>
                                                      )}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                      component="th"
                                                      scope="row"
                                                      align="center"
                                                      sx={{
                                                        color: "text.main",
                                                      }}
                                                    >
                                                      {question.answer && (
                                                        <>
                                                          {question.id &&
                                                            itemScores[
                                                              selectedOption
                                                                ?.score
                                                            ].name !==
                                                              "Satisfactory" &&
                                                            !riskRegisters.includes(
                                                              question.id
                                                            ) && (
                                                              <MDBox display="flex">
                                                                <MDButton
                                                                  onClick={() =>
                                                                    addToRiskRegister(
                                                                      question?.id
                                                                    )
                                                                  }
                                                                >
                                                                  <MDBox
                                                                    color="dark"
                                                                    display="flex"
                                                                    alignItems="center"
                                                                  >
                                                                    <Icon
                                                                      sx={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      add
                                                                    </Icon>
                                                                    <MDTypography
                                                                      variant="p"
                                                                      fontWeight="medium"
                                                                      color="text.main"
                                                                      sx={{
                                                                        lineHeight: 0,
                                                                        marginLeft:
                                                                          "0.5rem",
                                                                      }}
                                                                    >
                                                                      Add
                                                                    </MDTypography>
                                                                  </MDBox>
                                                                </MDButton>
                                                              </MDBox>
                                                            )}

                                                          {question.id &&
                                                            itemScores[
                                                              selectedOption
                                                                ?.score
                                                            ].name !==
                                                              "Satisfactory" &&
                                                            riskRegisters.includes(
                                                              question.id
                                                            ) && (
                                                              <>Already Added</>
                                                            )}
                                                        </>
                                                      )}
                                                    </StyledTableCell>
                                                  </StyledTableRow>
                                                );
                                              }
                                            )}
                                          </Fragment>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                )}
                              </TableContainer>
                            </Card>
                          </MDBox>
                        )}

                        {tab === 1 && (
                          <MDBox pt={4}>
                            <MDBox display="flex">
                              <MDTypography variant="h5" fontWeight="bold">
                                Overall Performance
                              </MDTypography>
                            </MDBox>

                            <Card sx={{ marginTop: "16px" }}>
                              <TableContainer sx={{ padding: "1rem" }}>
                                {isLoadingAssessment ? (
                                  <LoadingSpinner color="success" size="lg" />
                                ) : (
                                  <Table sx={{ color: "text.main" }}>
                                    <TableRow>
                                      <TableCell align="center">
                                        <b>Risk Level</b>
                                      </TableCell>
                                      <TableCell align="center">
                                        <b>Percentage</b>
                                      </TableCell>
                                    </TableRow>

                                    <TableBody>
                                      {Object.entries(itemScores).map(
                                        ([value, score], index) => {
                                          return (
                                            <StyledTableRow key={index}>
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border: 0,
                                                    },
                                                  backgroundColor: lightenColor(
                                                    score.color
                                                  ),
                                                  width: "30%",
                                                }}
                                              >
                                                {score.name}
                                              </TableCell>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                                align="center"
                                                sx={{ color: "text.main" }}
                                              >
                                                {(
                                                  (score.percentage /
                                                    currentAssessmentData
                                                      .questions.length) *
                                                  100.0
                                                ).toFixed(2)}{" "}
                                                %
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        }
                                      )}
                                    </TableBody>
                                  </Table>
                                )}
                              </TableContainer>
                            </Card>
                          </MDBox>
                        )}

                        {tab === 2 && (
                          <MDBox pt={4}>
                            <MDBox display="flex">
                              <MDTypography variant="h5" fontWeight="bold">
                                Category Performance
                              </MDTypography>
                            </MDBox>

                            <Card sx={{ marginTop: "16px" }}>
                              <TableContainer sx={{ padding: "1rem" }}>
                                {isLoadingAssessment ? (
                                  <LoadingSpinner color="success" size="lg" />
                                ) : (
                                  <Table sx={{ color: "text.main" }}>
                                    <TableRow>
                                      <TableCell align="center">
                                        <b>Category</b>
                                      </TableCell>
                                      {Object.entries(itemScores).map(
                                        ([value, score], index) => {
                                          return (
                                            <TableCell
                                              key={index}
                                              align="center"
                                            >
                                              <b>{score.name}</b>
                                            </TableCell>
                                          );
                                        }
                                      )}
                                    </TableRow>

                                    <TableBody>
                                      {Object.entries(
                                        currentAssessmentData.category
                                      ).map(([value, category], index) => {
                                        return (
                                          <StyledTableRow key={index}>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                              sx={{ color: "text.main" }}
                                            >
                                              {value}
                                            </StyledTableCell>
                                            {Object.entries(itemScores).map(
                                              ([valuee, score], index) => {
                                                return (
                                                  <TableCell
                                                    align="center"
                                                    sx={{
                                                      "&:last-child td, &:last-child th":
                                                        {
                                                          border: 0,
                                                        },
                                                      backgroundColor:
                                                        lightenColor(
                                                          score.color
                                                        ),
                                                    }}
                                                  >
                                                    {score[value] ? (
                                                      <>
                                                        {(
                                                          (score[value] /
                                                            category.length) *
                                                          100.0
                                                        ).toFixed(2)}{" "}
                                                        %
                                                      </>
                                                    ) : (
                                                      "0 %"
                                                    )}
                                                  </TableCell>
                                                );
                                              }
                                            )}
                                          </StyledTableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                )}
                              </TableContainer>
                            </Card>
                          </MDBox>
                        )}

                        {tab === 3 && (
                          <MDBox pt={4}>
                            <MDBox display="flex">
                              <MDTypography variant="h5" fontWeight="bold">
                                Risk Matrix Report
                              </MDTypography>
                            </MDBox>

                            <Card sx={{ marginTop: "16px" }}>
                              <TableContainer sx={{ padding: "1rem" }}>
                                {isLoadingAssessment ? (
                                  <LoadingSpinner color="success" size="lg" />
                                ) : (
                                  <Table sx={{ color: "text.main" }}>
                                    <TableRow>
                                      <TableCell align="center">
                                        <b>Item Name</b>
                                      </TableCell>
                                      <TableCell align="center">
                                        <b>Description</b>
                                      </TableCell>
                                      <TableCell align="center">
                                        <b>Impact Level</b>
                                      </TableCell>
                                      <TableCell align="center">
                                        <b>Probability Level</b>
                                      </TableCell>
                                      <TableCell align="center">
                                        <b>Priority Level</b>
                                      </TableCell>
                                      <TableCell align="center">
                                        <b>Owner</b>
                                      </TableCell>
                                      <TableCell align="center">
                                        <b>Action</b>
                                      </TableCell>
                                    </TableRow>

                                    <TableBody>
                                      {currentAssessmentData.riskMatrixItems.map(
                                        (item, index) => (
                                          <StyledTableRow key={index}>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                              sx={{ color: "text.main" }}
                                            >
                                              {item.item}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                              sx={{ color: "text.main" }}
                                              dangerouslySetInnerHTML={{
                                                __html: sanitizedExplanation(
                                                  item.explanation
                                                ),
                                              }}
                                            ></StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                              sx={{ color: "text.main" }}
                                            >
                                              {item.impact_level}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                              sx={{ color: "text.main" }}
                                            >
                                              {item.probability_level}
                                            </StyledTableCell>
                                            {item.priority_level < 6 && (
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border: 0,
                                                    },
                                                  backgroundColor:
                                                    lightenColor("#72ab48"),
                                                }}
                                              >
                                                Low
                                              </TableCell>
                                            )}

                                            {item.priority_level > 5 &&
                                              item.priority_level < 13 && (
                                                <TableCell
                                                  align="center"
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      {
                                                        border: 0,
                                                      },
                                                    backgroundColor:
                                                      lightenColor("#fefe06"),
                                                  }}
                                                >
                                                  Medium
                                                </TableCell>
                                              )}
                                            {item.priority_level > 12 &&
                                              item.priority_level < 21 && (
                                                <TableCell
                                                  align="center"
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      {
                                                        border: 0,
                                                      },
                                                    backgroundColor:
                                                      lightenColor("#ffbe05"),
                                                  }}
                                                >
                                                  High
                                                </TableCell>
                                              )}
                                            {item.priority_level > 20 && (
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border: 0,
                                                    },
                                                  backgroundColor:
                                                    lightenColor("#ff0102"),
                                                }}
                                              >
                                                Very High
                                              </TableCell>
                                            )}
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                              sx={{ color: "text.main" }}
                                            >
                                              {item.owner}
                                            </StyledTableCell>
                                            <TableCell>
                                              <ResponsiveFlexContainer>
                                                <RiskMatrixItemEditBtnTr
                                                  openEditMatrixDialogHandler={
                                                    openEditMatrixDialogHandler
                                                  }
                                                  objUser={objUser}
                                                  itemId={item.id}
                                                />
                                                <RiskMatrixItemDeleteBtnTr
                                                  eventId={eventId}
                                                  itemId={item.id}
                                                  objUser={objUser}
                                                  onDelete={onDelete}
                                                />
                                              </ResponsiveFlexContainer>
                                            </TableCell>
                                          </StyledTableRow>
                                        )
                                      )}
                                      <EditRiskMatrixDialog
                                        objUser={objUser}
                                        open={openEditRiskMatrixDialog}
                                        onClose={() =>
                                          setOpenEditRiskMatrixDialog(false)
                                        }
                                        onSubmit={onNewItem}
                                        eventId={eventId}
                                        itemId={itemId}
                                      />
                                    </TableBody>
                                  </Table>
                                )}
                              </TableContainer>
                            </Card>
                          </MDBox>
                        )}
                      </>
                    ) : (
                      <MDBox>
                        <Card
                          sx={{
                            padding: "10px",
                            textAlign: "center",
                            color: "text.main",
                          }}
                        >
                          <p>Assessment Not Started</p>
                        </Card>
                      </MDBox>
                    )}
                  </Grid>
                </Grid>
              </MDBox>
            </>
          )}
        </DashboardLayout>
      </MDBox>

      {/* Displays on on print screen  */}
      <MDBox className="print">
        <DashboardLayout>
          {isLoading ? (
            <Card>
              <LoadingSpinner color="success" size="lg" />
            </Card>
          ) : (
            <>
              <MDBox>
                <Grid
                  spacing={2}
                  container
                  width="100%"
                  sx={{ alignItems: "flex-start" }}
                >
                  <Grid item xs={12} lg={12}>
                    {totalCount > 0 ? (
                      <>
                        {reportLogoURL ? (
                          <MDBox
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <img
                              src={reportLogoURL}
                              style={{ height: "100px" }}
                              alt="Report logo"
                            />
                            <MDTypography mt={2} variant="h3">
                              Report on {currentAssessment.name}
                            </MDTypography>
                          </MDBox>
                        ) : (
                          ""
                        )}
                        <MDBox px={4} sx={{ visibility: "hidden" }}>
                          <Tabs value={tab} onChange={handleTabChange} centered>
                            <Tab label="Item Analysis" />
                            <Tab label="Overall Performance" />
                            <Tab label="Category Performance" />
                            <Tab label="Risk Matrix Report" />
                          </Tabs>
                        </MDBox>

                        <MDBox pt={4}>
                          <MDBox display="flex">
                            <MDTypography variant="h5" fontWeight="bold">
                              Item Analysis
                            </MDTypography>
                          </MDBox>

                          <Card sx={{ marginTop: "16px" }}>
                            <TableContainer sx={{ padding: "1rem" }}>
                              {isLoadingAssessment ? (
                                <LoadingSpinner color="success" size="lg" />
                              ) : (
                                <Table>
                                  <TableRow>
                                    <TableCell align="center">
                                      <b>Item Name</b>
                                    </TableCell>
                                    <TableCell align="center">
                                      <b>Risk Level</b>
                                    </TableCell>
                                  </TableRow>

                                  <TableBody>
                                    {Object.entries(
                                      currentAssessmentData.category ?? []
                                    ).map(([category, questions], catIndex) => (
                                      <Fragment key={category}>
                                        {questions.map((question, index) => {
                                          const selectedOption =
                                            question.options.find(
                                              (option) =>
                                                option.id === question.answer
                                            );
                                          return (
                                            <StyledTableRow key={index}>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                                align="center"
                                              >
                                                {question.item}
                                              </StyledTableCell>
                                              {question.answer ? (
                                                <TableCell
                                                  align="center"
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      {
                                                        border: 0,
                                                      },
                                                    backgroundColor:
                                                      lightenColor(
                                                        itemScores[
                                                          selectedOption?.score
                                                        ].color
                                                      ),
                                                    width: "30%",
                                                  }}
                                                >
                                                  {
                                                    itemScores[
                                                      selectedOption?.score
                                                    ].name
                                                  }
                                                </TableCell>
                                              ) : (
                                                <TableCell
                                                  align="center"
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      {
                                                        border: 0,
                                                      },
                                                    width: "30%",
                                                  }}
                                                >
                                                  Item not Completed
                                                </TableCell>
                                              )}
                                            </StyledTableRow>
                                          );
                                        })}
                                      </Fragment>
                                    ))}
                                  </TableBody>
                                </Table>
                              )}
                            </TableContainer>
                          </Card>
                        </MDBox>

                        <MDBox pt={4} className="page-break">
                          <MDBox display="flex">
                            <MDTypography variant="h5" fontWeight="bold">
                              Overall Performance
                            </MDTypography>
                          </MDBox>

                          <Card sx={{ marginTop: "16px" }}>
                            <TableContainer sx={{ padding: "1rem" }}>
                              {isLoadingAssessment ? (
                                <LoadingSpinner color="success" size="lg" />
                              ) : (
                                <Table>
                                  <TableRow>
                                    <TableCell align="center">
                                      <b>Risk Level</b>
                                    </TableCell>
                                    <TableCell align="center">
                                      <b>Percentage</b>
                                    </TableCell>
                                  </TableRow>

                                  <TableBody>
                                    {Object.entries(itemScores).map(
                                      ([value, score], index) => {
                                        return (
                                          <StyledTableRow key={index}>
                                            <TableCell
                                              align="center"
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: 0,
                                                  },
                                                backgroundColor: lightenColor(
                                                  score.color
                                                ),
                                                width: "30%",
                                              }}
                                            >
                                              {score.name}
                                            </TableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                            >
                                              {(
                                                (score.percentage /
                                                  currentAssessmentData
                                                    .questions.length) *
                                                100.0
                                              ).toFixed(2)}{" "}
                                              %
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        );
                                      }
                                    )}
                                  </TableBody>
                                </Table>
                              )}
                            </TableContainer>
                          </Card>
                        </MDBox>

                        <MDBox pt={4} className="page-break">
                          <MDBox display="flex">
                            <MDTypography variant="h5" fontWeight="bold">
                              Category Performance
                            </MDTypography>
                          </MDBox>

                          <Card sx={{ marginTop: "16px" }}>
                            <TableContainer sx={{ padding: "1rem" }}>
                              {isLoadingAssessment ? (
                                <LoadingSpinner color="success" size="lg" />
                              ) : (
                                <Table>
                                  <TableRow>
                                    <TableCell align="center">
                                      <b>Category</b>
                                    </TableCell>
                                    {Object.entries(itemScores).map(
                                      ([value, score], index) => {
                                        return (
                                          <TableCell key={index} align="center">
                                            <b>{score.name}</b>
                                          </TableCell>
                                        );
                                      }
                                    )}
                                  </TableRow>

                                  <TableBody>
                                    {Object.entries(
                                      currentAssessmentData.category
                                    ).map(([value, category], index) => {
                                      return (
                                        <StyledTableRow key={index}>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                          >
                                            {value}
                                          </StyledTableCell>
                                          {Object.entries(itemScores).map(
                                            ([valuee, score], index) => {
                                              return (
                                                <TableCell
                                                  align="center"
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      {
                                                        border: 0,
                                                      },
                                                    backgroundColor:
                                                      lightenColor(score.color),
                                                    // width: "30%",
                                                  }}
                                                >
                                                  {score[value] ? (
                                                    <>
                                                      {(
                                                        (score[value] /
                                                          category.length) *
                                                        100.0
                                                      ).toFixed(2)}{" "}
                                                      %
                                                    </>
                                                  ) : (
                                                    "0 %"
                                                  )}
                                                </TableCell>
                                              );
                                            }
                                          )}
                                        </StyledTableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              )}
                            </TableContainer>
                          </Card>
                        </MDBox>

                        <MDBox pt={4} className="page-break">
                          <MDBox display="flex">
                            <MDTypography variant="h5" fontWeight="bold">
                              Risk Matrix Report
                            </MDTypography>
                          </MDBox>

                          <Card sx={{ marginTop: "16px" }}>
                            <TableContainer sx={{ padding: "1rem" }}>
                              {isLoadingAssessment ? (
                                <LoadingSpinner color="success" size="lg" />
                              ) : (
                                <Table>
                                  <TableRow>
                                    <TableCell align="center">
                                      <b>Item Name</b>
                                    </TableCell>
                                    <TableCell align="center">
                                      <b>Description</b>
                                    </TableCell>
                                    <TableCell align="center">
                                      <b>Impact Level</b>
                                    </TableCell>
                                    <TableCell align="center">
                                      <b>Probability Level</b>
                                    </TableCell>
                                    <TableCell align="center">
                                      <b>Priority Level</b>
                                    </TableCell>
                                    <TableCell align="center">
                                      <b>Owner</b>
                                    </TableCell>
                                  </TableRow>

                                  <TableBody>
                                    {currentAssessmentData.riskMatrixItems.map(
                                      (item, index) => (
                                        <StyledTableRow key={index}>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                          >
                                            {item.item}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                          >
                                            {item.explanation ?? "-"}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                          >
                                            {item.impact_level}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                          >
                                            {item.probability_level}
                                          </StyledTableCell>
                                          {item.priority_level < 6 && (
                                            <TableCell
                                              align="center"
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: 0,
                                                  },
                                                backgroundColor:
                                                  lightenColor("#72ab48"),
                                              }}
                                            >
                                              Low
                                            </TableCell>
                                          )}

                                          {item.priority_level > 5 &&
                                            item.priority_level < 13 && (
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border: 0,
                                                    },
                                                  backgroundColor:
                                                    lightenColor("#fefe06"),
                                                }}
                                              >
                                                Medium
                                              </TableCell>
                                            )}
                                          {item.priority_level > 12 &&
                                            item.priority_level < 21 && (
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border: 0,
                                                    },
                                                  backgroundColor:
                                                    lightenColor("#ffbe05"),
                                                }}
                                              >
                                                High
                                              </TableCell>
                                            )}
                                          {item.priority_level > 20 && (
                                            <TableCell
                                              align="center"
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: 0,
                                                  },
                                                backgroundColor:
                                                  lightenColor("#ff0102"),
                                              }}
                                            >
                                              Very High
                                            </TableCell>
                                          )}
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                          >
                                            {item.owner}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              )}
                            </TableContainer>
                          </Card>
                        </MDBox>
                      </>
                    ) : (
                      <MDBox>
                        <Card sx={{ padding: "10px", textAlign: "center" }}>
                          <p>Assessment Not Started</p>
                        </Card>
                      </MDBox>
                    )}
                  </Grid>
                </Grid>
              </MDBox>
            </>
          )}
        </DashboardLayout>
      </MDBox>

      {selectedQuestions.length > 0 ? (
        <>
          <MDButton
            color="dark"
            onClick={() => {
              setItemId(null);
              setOpenNewRiskMatrixDialog(true);
            }}
            sx={{
              position: "fixed",
              bottom: "3rem",
              right: "15rem",
              padding: "20px",
            }}
            aria-label="Scroll to top"
            title="Scroll to top"
          >
            Add to Risk Matrix
          </MDButton>
          <MDButton
            color="dark"
            onClick={() => bulkAddRiskRegisterItems()}
            sx={{
              position: "fixed",
              bottom: "3rem",
              right: "3rem",
              padding: "20px",
            }}
            aria-label="Scroll to top"
            title="Scroll to top"
          >
            Add to Risk Register
          </MDButton>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AssessmentReports;

import Card from "@mui/material/Card";
import { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";

import { unwrapResult } from "@reduxjs/toolkit";
import { getAssessmentTemplates } from "features/company/superAdminActions.js";
import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg, standardDateFormat } from "utils/general";
import globalDataTableData from "./components/GlobalTemplatesDataTableData.js";
import sharedDataTableData from "./components/SharedTemplatesDataTableData.js";
import TemplatesDatatable from "./components/TemplatesDataTable";
import CreateNewTemplateDialog from "./components/createNewTemplateDialog";
import ImportTemplateCSVDialog from "./components/ImportTemplateCSVDialog";
import { displayExceptionErrorMessage } from "utils/general.js";

const GlobalAssessmentTemplates = (props) => {
  const { objUser } = props;
  const [openNewTemplateDialog, setOpenNewTemplateDialog] = useState(false);
  const [openImportTemplateDialogCSV, setOpenImportTemplateDialogCSV] =
    useState(false);
  const [isLoadingClientTemplates, setIsLoadingClientTemplates] =
    useState(false);
  const [objGlobalTableData, setObjGlobalTableData] =
    useState(globalDataTableData);
  const [objSharedTableData, setObjSharedTableData] = useState(
    sharedDataTableData(objUser, () => fetchAssessmentTemplates())
  );
  const [globalTotalCount, setGlobalTotalCount] = useState(0);
  const [sharedTotalCount, setSharedTotalCount] = useState(0);
  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const btnOnClickNewTemplate = () => {
    setOpenNewTemplateDialog(true);
  };

  const onCloseNewTemplateDialog = () => {
    setOpenNewTemplateDialog(false);
  };

  const btnOnClickImportTemplate = () => {
    setOpenImportTemplateDialogCSV(true);
  };

  const onCloseImportTemplateDialogCSV = () => {
    setOpenImportTemplateDialogCSV(false);
  };

  const onSubmitImportTemplateDialogCSV = () => {
    setOpenImportTemplateDialogCSV(false);
    showMsg("success", "New Template added successfully");
    fetchAssessmentTemplates();
  };

  const onSubmitNewTemplateDialog = (templateId, name) => {
    setOpenNewTemplateDialog(false);
    showMsg("success", "New Template added successfully");
    routeDictionary[templateId] = name;
    navigate(`/super-admin/assessment-templates/${templateId}`, {
      state: { routeDictionary },
    });
  };

  const fetchAssessmentTemplates = () => {
    if (objUser.user.is_super_admin != 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    setIsLoadingClientTemplates(true);
    dispatch(getAssessmentTemplates({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingClientTemplates(false);
        const response = originalPromiseResult;

        //console.log("### Templates Response", response);

        const objGlobalRows = [];
        const objSharedRows = [];
        const objClientTemplatesList = response.data.templates;
        for (let index = 0; index < objClientTemplatesList.length; index++) {
          const element = objClientTemplatesList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.name = element.name;
          objTr.created_by = element?.created_by;
          objTr.created_at = standardDateFormat(element.created_at);
          objTr.is_global_template = element?.is_global_template;
          objTr.shareRequest = element?.shareRequest;
          objTr.subscription_category = element?.subscription_category ?? "";
          objTr.subscription_category =
            objTr.subscription_category.charAt(0).toUpperCase() +
            objTr.subscription_category.slice(1);
          objTr.description = element?.description;
          objTr.status = element?.status;
          objTr.element = element;
          objTr.objUser = objUser;
          if (objTr.is_global_template) {
            objGlobalRows.push(objTr);
          }
          if (objTr.shareRequest) {
            objTr.status = objTr.shareRequest.status;
          objTr.created_by = element?.msp;
            objSharedRows.push(objTr);
          }
        }

        setGlobalTotalCount(objGlobalRows.length); // There is no pagination on the backend, so that is ok
        setObjGlobalTableData({
          ...globalDataTableData(objUser, () => fetchAssessmentTemplates()),
          rows: objGlobalRows,
        });
        setSharedTotalCount(objSharedRows.length); // There is no pagination on the backend, so that is ok
        setObjSharedTableData({
          ...sharedDataTableData(objUser, () => fetchAssessmentTemplates()),
          rows: objSharedRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingClientTemplates(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {
    fetchAssessmentTemplates();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ImportTemplateCSVDialog
        open={openImportTemplateDialogCSV}
        onClose={onCloseImportTemplateDialogCSV}
        onSubmit={onSubmitImportTemplateDialogCSV}
        objUser={props.objUser}
      />
      <CreateNewTemplateDialog
        open={openNewTemplateDialog}
        onClose={onCloseNewTemplateDialog}
        onSubmit={onSubmitNewTemplateDialog}
        objUser={props.objUser}
      />
      <MDBox>
        <Card
          position="relative"
          sx={{ padding: "32px", marginBottom: "1rem" }}
        >
          <MDBox
            mb={1}
            textAlign="left"
            sx={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <MDTypography variant="h3">
              Global Assessment Templates
            </MDTypography>
            <MDBox
              sx={{
                display: "inline-flex",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <DashboardActionButton
                action={btnOnClickNewTemplate}
                btnText="Create Template"
                btnIcon="add"
                textColor="white"
                bgColor="success"
                sx={{ margin: "0" }}
              />
              <DashboardActionButton
                action={btnOnClickImportTemplate}
                btnText="Import Template From CSV"
                btnIcon="add"
                textColor="white"
                bgColor="success"
                sx={{ margin: "0" }}
              />
            </MDBox>
          </MDBox>
          {isLoadingClientTemplates === false &&
          objGlobalTableData.rows.length > 0 &&
          globalTotalCount > 0 ? (
            <TemplatesDatatable
              table={objGlobalTableData}
              entriesPerPage={true}
              canSearch
              totalCount={globalTotalCount}
              objUser={objUser}
            />
          ) : (
            <Fragment>
              {isLoadingClientTemplates ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p className="empty-text-p">
                  There are no Global Templates available in your subscription
                  tier.
                </p>
              )}
            </Fragment>
          )}
        </Card>

        <Card
          position="relative"
          sx={{ padding: "32px", marginBottom: "1rem" }}
        >
          <MDBox
            mb={1}
            textAlign="left"
            sx={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <MDTypography variant="h3">
              MSP Shared Assessment Templates
            </MDTypography>
          </MDBox>
          {isLoadingClientTemplates === false &&
          objSharedTableData.rows.length > 0 &&
          sharedTotalCount > 0 ? (
            <TemplatesDatatable
              table={objSharedTableData}
              entriesPerPage={true}
              canSearch
              totalCount={globalTotalCount}
              objUser={objUser}
            />
          ) : (
            <Fragment>
              {isLoadingClientTemplates ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p className="empty-text-p">
                  There are no Shared MSP Templates.
                </p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default GlobalAssessmentTemplates;

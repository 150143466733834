import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ImageIcon from "@mui/icons-material/Image";
import ListItemIcon from "@mui/material/ListItemIcon";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ListItemText from "@mui/material/ListItemText";
import DOMPurify from "dompurify";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Card } from "@mui/material";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { fetchPeerGroupPostDetails } from "features/company/peerGroupActions.js";
import PeerGroupFileEmbed from "./PeerGroupFileEmbed";
import { displayExceptionErrorMessage } from "utils/general";

DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  // Check if the node is an <a> tag
  if (node.nodeName.toLowerCase() === "a") {
    // Add rel="noopener noreferrer" and target="_blank" attributes
    node.setAttribute("rel", "noopener noreferrer");
    node.setAttribute("target", "_blank");
  }
});

function PeerGroupPostsDetails({ objUser }) {
  const dispatch = useDispatch();
  let { post_id } = useParams();
  const [peerGroupPost, setPeerGroupPost] = useState({});
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();

  const handleListItemClick = (file, index) => {
    setSelectedIndex(index);
    setSelectedFile(file);
  };

  const fetchPost = () => {
    setIsLoading(true);
    dispatch(fetchPeerGroupPostDetails({ post_id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 200) {
          setPeerGroupPost(response.data);
          setFiles(response.data.files);
          setIsLoading(false);
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (rejectedValueOrSerializedError?.response?.status == 403) {
          setPeerGroupPost({
            content_type: "paid",
          });
        } else {
          setIsLoading(false);
          displayExceptionErrorMessage(rejectedValueOrSerializedError);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchPost();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar objUser={objUser} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          {peerGroupPost.content_type == "paid" ? (
            <Card
              position="relative"
              sx={{ padding: "32px", marginBottom: "1rem" }}
            >
              {objUser?.user?.company?.has_peer_group_subscription &&
              typeof objUser?.user?.company?.has_peer_group_subscription !==
                "undefined" &&
              objUser?.user?.company?.has_peer_group_subscription === true &&
              (objUser?.user?.role?.name === "admin" ||
                objUser?.user?.role?.name === "company_user" ||
                objUser?.user?.role?.name === "peer_group_admin") ? (
                <>
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <MDBox mb={3} textAlign="left">
                          <MDTypography variant="h3">
                            {peerGroupPost.title}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid container columnSpacing={5} rowSpacing={3}>
                        <Grid item xs={12} sm={4} md={4} pt={0}>
                          <Accordion expanded>
                            <AccordionSummary
                              sx={{ borderBottom: "thick, solid, black" }}
                              // expandIcon={<ExpandMoreIcon color="icon" />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <MDTypography>Description</MDTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <MDTypography>
                                {peerGroupPost.description}
                              </MDTypography>
                            </AccordionDetails>
                          </Accordion>

                          <MDBox mb={2} borderRadius="sm" shadow="sm">
                            <List>
                              <ListItemButton
                                selected={!selectedFile}
                                onClick={() => {
                                  setSelectedFile(null);
                                  setSelectedIndex(null);
                                }}
                              >
                                <ListItemText
                                  primary="Video"
                                  sx={{ color: "text.main" }}
                                />
                              </ListItemButton>
                            </List>
                          </MDBox>

                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon color="icon" />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                            >
                              <MDTypography>Files</MDTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {files.length > 0 && (
                                <>
                                  {files.map((file, index) => (
                                    <List
                                      key={index}
                                      component="nav"
                                      aria-label="secondary mailbox folder"
                                    >
                                      <ListItemButton
                                        selected={selectedIndex === index}
                                        onClick={(event) =>
                                          handleListItemClick(file, index)
                                        }
                                      >
                                        <ListItemIcon>
                                          {file.file_type === "pdf" ? (
                                            <PictureAsPdfIcon />
                                          ) : (
                                            <ImageIcon />
                                          )}
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={file.file_name}
                                          sx={{ color: "text.main" }}
                                        />
                                      </ListItemButton>
                                    </List>
                                  ))}
                                </>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        {!selectedFile ? (
                          <Grid item xs={12} sm={8} md={8} pt={0}>
                            <MDBox mb={0}>
                              {peerGroupPost.video_provider === "custom" ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      peerGroupPost.video_url ?? "",
                                      {
                                        USE_PROFILES: { html: true },
                                        ADD_TAGS: ["iframe"],
                                      }
                                    ),
                                  }}
                                />
                              ) : (
                                <iframe
                                  src={peerGroupPost.video_url}
                                  title={peerGroupPost.title}
                                  allowFullScreen
                                  frameBorder="0"
                                  style={{ width: "100%", height: "26rem" }}
                                ></iframe>
                              )}
                            </MDBox>
                          </Grid>
                        ) : (
                          <PeerGroupFileEmbed file={selectedFile} />
                        )}
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <>
                  {objUser?.user?.role?.name === "admin" ||
                  objUser?.user?.role?.name === "peer_group_admin" ? (
                    <>
                      <Grid container mt={2}>
                        <Grid item xs={12}>
                          <MDBox
                            mb={3}
                            textAlign="left"
                            sx={{
                              display: "inline-flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <MDTypography variant="h3">
                              Peer Group Resources
                            </MDTypography>
                            <Link to="/billing">
                              <MDButton color="info">
                                Signup for Peer Group
                              </MDButton>
                            </Link>
                          </MDBox>
                        </Grid>
                      </Grid>
                      <MDBox mb={0}>
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="success"
                        ></MDTypography>
                        <MDBox>
                          <stripe-pricing-table
                            pricing-table-id={
                              process.env.REACT_APP_PEER_GROUP_PLAN_TABLE_ID
                            }
                            publishable-key={
                              process.env
                                .REACT_APP_PEER_GROUP_PLAN_STRIPE_PUBLISHABLE_KEY
                            }
                            customer-email={objUser.user.company.email}
                            client-reference-id={objUser.user.company.id}
                          ></stripe-pricing-table>
                        </MDBox>
                      </MDBox>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Card>
          ) : (
            <Card
              position="relative"
              sx={{ padding: "32px", marginBottom: "1rem" }}
            >
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Grid item xs={12}>
                    <MDBox mb={3} textAlign="left">
                      <MDTypography variant="h3">
                        {peerGroupPost.title}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid container columnSpacing={5} rowSpacing={3}>
                    <Grid item xs={12} sm={4} md={4} pt={0}>
                      <Accordion expanded>
                        <AccordionSummary
                          sx={{ borderBottom: "thick, solid, black" }}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <MDTypography>Description</MDTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <MDTypography>
                            {peerGroupPost.description}
                          </MDTypography>
                        </AccordionDetails>
                      </Accordion>

                      <MDBox mb={2} borderRadius="sm" shadow="sm">
                        <List>
                          <ListItemButton
                            selected={!selectedFile}
                            onClick={() => {
                              setSelectedFile(null);
                              setSelectedIndex(null);
                            }}
                          >
                            <ListItemText
                              primary="Video"
                              sx={{ color: "text.main" }}
                            />
                          </ListItemButton>
                        </List>
                      </MDBox>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon color="icon" />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <MDTypography>Files</MDTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {files.length > 0 && (
                            <>
                              {files.map((file, index) => (
                                <List
                                  key={index}
                                  component="nav"
                                  aria-label="secondary mailbox folder"
                                >
                                  <ListItemButton
                                    selected={selectedIndex === index}
                                    onClick={(event) =>
                                      handleListItemClick(file, index)
                                    }
                                  >
                                    <ListItemIcon>
                                      {file.file_type === "pdf" ? (
                                        <PictureAsPdfIcon />
                                      ) : (
                                        <ImageIcon />
                                      )}
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={file.file_name}
                                      sx={{ color: "text.main" }}
                                    />
                                  </ListItemButton>
                                </List>
                              ))}
                            </>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    {!selectedFile ? (
                      <Grid item xs={12} sm={8} md={8} pt={0}>
                        <MDBox mb={0}>
                          {peerGroupPost.video_provider === "custom" ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  peerGroupPost.video_url ?? "",
                                  {
                                    USE_PROFILES: { html: true },
                                    ADD_TAGS: ["iframe"],
                                  }
                                ),
                              }}
                            />
                          ) : (
                            <iframe
                              src={peerGroupPost.video_url}
                              title={peerGroupPost.title}
                              allowFullScreen
                              frameBorder="0"
                              style={{ width: "100%", height: "26rem" }}
                            ></iframe>
                          )}
                        </MDBox>
                      </Grid>
                    ) : (
                      <PeerGroupFileEmbed file={selectedFile} />
                    )}
                  </Grid>
                </>
              )}
            </Card>
          )}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default PeerGroupPostsDetails;

import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import { useNavigate, useLocation } from "react-router-dom";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import { useCookies } from "react-cookie";
import ToolTip from "views/components/Tooltip/ToolTip";
import fixLogo from "assets/images/fix.jpg";
import cancelLogo from "assets/images/cancelLogo.jpg";
import twoFA from "assets/images/2fa.png";
import reassign from "assets/images/reassign.png";
import transfer from "assets/images/transfer-template.png";
import DashboardButton from "views/components/DashboardButtton";

const SuperAdminToolKit = (props) => {
  const { objUser } = props;
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  useEffect(() => {
    if (objUser.user.is_super_admin !== 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox>
        <Grid spacing={2} container width="100%">
          <Grid item xs={12}>
            <MDTypography
              fontWeight="bold"
              variant="h5"
              display="flex"
              alignItems="flex-start"
            >
              Toolkit
              <ToolTip info={"Resolve peculiar edge cases."} />
            </MDTypography>
          </Grid>
          {objUser.user.email.includes("@gtnllc.com") ? (
            <Grid item xs={12} lg={4}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <Grid item xs={12} lg={2}>
                      <img src={fixLogo} alt="fix" />
                    </Grid>
                    <MDTypography variant="h5" fontWeight="bold">
                      Assessment Event Fix
                    </MDTypography>
                    <MDTypography variant="p" fontWeight="regular">
                      Inject new questions into ongoing assessment events and
                      imported templates
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="bold">
                      <DashboardButton
                        component={Link}
                        to={`/super-admin/toolkit/assessment-event-fix`}
                        state={{ routeDictionary }}
                        btnText="Config"
                        btnIcon="settings"
                        textColor="white"
                        bgColor="success"
                      />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} lg={4}>
            <Card sx={{ marginTop: "16px" }}>
              <MDBox p={2}>
                <Grid>
                  <Grid item xs={12} lg={2}>
                    <img src={twoFA} alt="fix" />
                  </Grid>
                  <MDTypography variant="h5" fontWeight="bold">
                    Reset 2FA
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    Remove the MFA settings associated with a user. This allows
                    them to set it up again
                  </MDTypography>
                  <MDTypography variant="h5" fontWeight="bold">
                    <DashboardButton
                      component={Link}
                      to={`/super-admin/toolkit/2fa-fix`}
                      state={{ routeDictionary }}
                      btnText="Config"
                      btnIcon="settings"
                      textColor="white"
                      bgColor="success"
                    />
                  </MDTypography>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card sx={{ marginTop: "16px" }}>
              <MDBox p={2}>
                <Grid>
                  <Grid item xs={12} lg={2}>
                    <img src={reassign} alt="fix" />
                  </Grid>
                  <MDTypography variant="h5" fontWeight="bold">
                    Reassign User to Company
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    Reassign a user who tried creating an MSP instead of
                    accepting an email invite to a company or client.
                  </MDTypography>
                  <MDTypography variant="h5" fontWeight="bold">
                    <DashboardButton
                      component={Link}
                      to={`/super-admin/toolkit/reassign-user-to-company`}
                      state={{ routeDictionary }}
                      btnText="Config"
                      btnIcon="settings"
                      textColor="white"
                      bgColor="success"
                    />
                  </MDTypography>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          {objUser.user.email.includes("@gtnllc.com") ? (
            <Grid item xs={12} lg={4}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <Grid item xs={12} lg={2.6}>
                      <img src={cancelLogo} alt="Cancel logo" />
                    </Grid>
                    <MDTypography variant="h5" fontWeight="bold">
                      Cancel MSP Account
                    </MDTypography>
                    <MDTypography variant="p" fontWeight="regular">
                      Remove an MSP's account and remove all traces of their
                      information
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="bold">
                      <DashboardButton
                        component={Link}
                        to={`/super-admin/toolkit/cancel-account-fix`}
                        state={{ routeDictionary }}
                        btnText="Config"
                        btnIcon="settings"
                        textColor="white"
                        bgColor="success"
                      />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} lg={4}>
            <Card sx={{ marginTop: "16px" }}>
              <MDBox p={2}>
                <Grid>
                  <Grid item xs={12} lg={2}>
                    <img src={transfer} alt="fix" />
                  </Grid>
                  <MDTypography variant="h5" fontWeight="bold">
                    Import Template from Staging
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    Import Assessment and Policy templates from staging into
                    production
                  </MDTypography>
                  <MDTypography variant="h5" fontWeight="bold">
                    <DashboardButton
                      component={Link}
                      to={`/super-admin/toolkit/import-template-from-staging`}
                      state={{ routeDictionary }}
                      btnText="Config"
                      btnIcon="settings"
                      textColor="white"
                      bgColor="success"
                    />
                  </MDTypography>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default SuperAdminToolKit;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import DefaultCell from "views/components/TableCells/DefaultCell";
import NameBtnTr from "./NameBtnTr";
const DataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "customerName",
      Cell: ({ value, column, row }) => (
        <NameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "address",
      accessor: "address",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default DataTableData;

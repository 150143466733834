// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { Fragment, useEffect, useState } from "react";

// formik components
// import { Form, Formik } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useParams } from "react-router-dom";
// Authentication layout components
// Other authentication methods components
// Images
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchWisp, approveWisp } from "features/wisps/wispActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDTypography from "components/MDTypography";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import { useMaterialUIController } from "context";
// import { standardDateFormat } from "utils/general";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import DashboardActionButton from "views/components/DashboardActionButton";
import ArticleIcon from "@mui/icons-material/Article";
import useWispPDFDownloader from "../../useWispPDFDownloader";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PDFDownloadSettingsDialog from "views/pages/Dashboard/CompanyConfig/components/PDFDownloadSettingsDialog";

const Index = (props) => {
  const { objUser } = props;
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isLoadingWisp, setIsLoadingWisp] = useState(false);
  const [isApprovingWisp, setIsApprovingWisp] = useState(false);
  const [wispData, setWispData] = useState(null);
  const [wispAdditionalSectionsData, setWispAdditionalSectionsData] = useState(
    []
  );
  const [currentUserIsApprover, setCurrentUserIsApprover] = useState(false);
  const [wispObj, setWispObj] = useState(null);
  const [controller] = useMaterialUIController();
  const { downloadWispPDF, isLoadingPDF } = useWispPDFDownloader();
  const { darkMode } = controller;

  useEffect(() => {
    // if (
    //   objUser.user.role.name != "admin" &&
    //   objUser.user.role.name != "company_user"
    // ) {
    //   navigate(`/home`, {
    //     state: { routeDictionary },
    //   });
    // }
    loadWisp();
  }, []);

  const loadWisp = () => {
    setIsLoadingWisp(true);
    dispatch(fetchWisp({ id, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingWisp(false);
        setWispData(originalPromiseResult.data.wispPolicies);
        setWispObj(originalPromiseResult.data.wisp);
        setWispAdditionalSectionsData(
          originalPromiseResult.data.wispAdditionalSections
        );
        setCurrentUserIsApprover(
          originalPromiseResult.data.currentUserIsApprover
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingWisp(false);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  /**pdf display settings */
  const [displayPdfSettingsValue, setDisplayPdfSettingValue] = useState(
    objUser?.user?.company?.pdf_display_settings ?? null
  );
  const [openPdfSettingsDialog, setOpenPdfSettingsDialog] = useState(false);
  const onclosePdfSettingsDialog = () => {
    setOpenPdfSettingsDialog(false);
  };
  /**end pdf display settings */

  const btnClickDownloadWispPDF = (dataObj = null) => {
    downloadWispPDF(objUser, wispObj.id, displayPdfSettingsValue);
  };

  const btnClickApproveWisp = () => {
    setIsApprovingWisp(true);

    dispatch(
      approveWisp({
        objUser,
        wispObj,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        //setIsApprovingWisp(false);
        window.location.reload();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsApprovingWisp(false);
        // const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Wisp could not be approved.");
      });
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <PDFDownloadSettingsDialog
          openPdfSettingsDialog={openPdfSettingsDialog}
          onclosePdfSettingsDialog={onclosePdfSettingsDialog}
          displayPdfSettingsValue={displayPdfSettingsValue}
          setDisplayPdfSettingValue={setDisplayPdfSettingValue}
          startDownload={btnClickDownloadWispPDF}
          dataObj={null}
          isLoadingPDF={isLoadingPDF}
        />
        <Grid container width="100%" spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox
              display="flex"
              gap=".6rem"
              alignItems="right"
              justifyContent="right"
              mb={2}
            >
              {isLoadingPDF ? (
                <MDBox mt={2}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                <DashboardActionButton
                  // action={btnClickDownloadWispPDF}
                  action={() => setOpenPdfSettingsDialog(true)}
                  btnText="Download PDF"
                  btnIcon="download"
                  textColor="white"
                  bgColor="success"
                />
              )}
              {currentUserIsApprover &&
              wispObj?.status === "Awaiting authorization" ? (
                <>
                  {isApprovingWisp ? (
                    <MDBox mt={2}>
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="lg"
                      />
                    </MDBox>
                  ) : (
                    <DashboardActionButton
                      action={btnClickApproveWisp}
                      btnText="Approve WISP"
                      btnIcon="check"
                      textColor="white"
                      bgColor="info"
                    />
                  )}
                </>
              ) : (
                ""
              )}
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                width: "100%",
                padding: "1rem",
              }}
            >
              {isLoadingWisp ? (
                <MDBox mt={2}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                <>
                  {wispData === null ? (
                    <>
                      <MDBox mb={2}>
                        <MDTypography variant="p" fontWeight="regular">
                          WISP not found
                        </MDTypography>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <Grid container>
                        <MDBox
                          mb={3}
                          sx={{
                            justifyContent: "space-between",
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <MDTypography color="dark" variant="h5">
                            {wispObj?.name}
                          </MDTypography>
                          {wispObj?.status === "Awaiting authorization" ? (
                            <MDTypography
                              sx={{ color: "#c21515" }}
                              variant="h6"
                            >
                              <AccessTimeIcon
                                sx={{ transform: "translateY(2px)" }}
                              />
                              Awaiting approval
                            </MDTypography>
                          ) : (
                            <MDTypography
                              sx={{ color: "#38b54a" }}
                              variant="h6"
                            >
                              <CheckCircleIcon
                                sx={{ transform: "translateY(2px)" }}
                              />
                              Approved
                            </MDTypography>
                          )}
                        </MDBox>
                        {/* wisp additional sections data */}
                        {wispAdditionalSectionsData.map((section) => {
                          return (
                            <Grid item xs={12} pt={0} key={section.id} mb={3}>
                              {section.section_content == "" ||
                              section.section_content == null ||
                              section?.section_content?.length == 0 ? (
                                ""
                              ) : (
                                <div>
                                  <MDTypography
                                    sx={{
                                      marginBottom: "10px",
                                      marginTop: "20px",
                                    }}
                                    color="success"
                                    variant="h5"
                                    fontWeight="bold"
                                  >
                                    {section.owning_global_section.title}
                                  </MDTypography>
                                  <Card
                                    sx={{
                                      padding: "0.7rem",
                                      overflowX: "scroll",
                                    }}
                                  >
                                    {/* document html */}
                                    <MDBox sx={{ width: "100%" }}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: section.section_content,
                                        }}
                                      />
                                    </MDBox>
                                  </Card>
                                </div>
                              )}
                            </Grid>
                          );
                        })}

                        {wispData.map((policy) => {
                          return (
                            <Grid item xs={12} pt={0} key={policy.id} mb={3}>
                              <Accordion
                                defaultExpanded={true}
                                sx={
                                  darkMode
                                    ? { backgroundColor: "#2f3958" }
                                    : { backgroundColor: "#ededed" }
                                }
                              >
                                <AccordionSummary
                                  sx={{ flexDirection: "row-reverse" }}
                                  expandIcon={<ExpandMoreIcon color="icon" />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <MDTypography variant="h6" p={1}>
                                    {policy.owning_policy.title}
                                  </MDTypography>
                                  <MDBox
                                    sx={{
                                      position: "absolute",
                                      right: "0.8rem",
                                    }}
                                  >
                                    {policy.kb_article_link ? (
                                      <a
                                        href={policy.kb_article_link}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <MDButton
                                          color="dark"
                                          sx={{
                                            marginBottom: "2rem",
                                            display: "flex",
                                            justifyContent: "center",
                                            gap: 1,
                                          }}
                                        >
                                          <ArticleIcon></ArticleIcon>
                                          View Knowledgebase Article
                                        </MDButton>
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </MDBox>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {policy.owning_policy.doc_type === "oscal" ? (
                                    <>
                                      {policy.owning_policy_document.documentSections.map(
                                        (section) => {
                                          return (
                                            <Fragment key={section.id}>
                                              {section.section_content == "" ||
                                              section.section_content ==
                                                null ? (
                                                ""
                                              ) : (
                                                <>
                                                  <MDTypography
                                                    variant="h4"
                                                    color="info"
                                                  >
                                                    {
                                                      section
                                                        .owning_global_section
                                                        .title
                                                    }
                                                  </MDTypography>
                                                  <MDBox mt={1} mb={2}>
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          section.section_content,
                                                      }}
                                                    />
                                                  </MDBox>
                                                </>
                                              )}
                                            </Fragment>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  );
};

export default Index;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";

//Gamification
import GamificationSidebar from "views/components/GamificationSidebar";

import { useCookies } from "react-cookie";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "views/components/Breadcrumbs";
import NotificationItem from "views/components/Items/NotificationItem";
import PostDefaultLogo from "assets/images/icons/logo-header.png";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarDesktopMenu,
  navbarMobileMenu,
  navbarRow,
} from "views/components/Navbars/DashboardNavbar/styles";

import { getAllPendingReviewsForUser } from "features/user/notificationActions";
import { fetchAutoLogoutTimer } from "../../../../features/company/CompanyConfigActions";

// Material Dashboard 2 PRO React context
import {
  setMiniSidenav,
  setOpenConfigurator,
  setTransparentNavbar,
  useMaterialUIController,
  setDarkMode,
} from "context";
import colors from "assets/theme-dark/base/colors";

import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuth0 } from "@auth0/auth0-react";

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { display, width } from "@mui/system";
import { getNewVersionInfo } from "features/company/clientActions";
import DOMPurify from "dompurify";

const NotificationBell = ({ darkMode }) => {
  const [open, setOpen] = useState(false);
  const [pendingPolicyActionsCount, setPendingPolicyActionsCount] = useState({
    pendingReviewsCount: 0,
    pendingApprovalsCount: 0,
    pendingLegalCount: 0,
    pendingWispApprovalsCount: 0,
    pendingTotal: 0,
  });
  const dispatch = useDispatch();

  const showNotificationsDialog = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchPendingReviewsForUser();
  }, []);

  const fetchPendingReviewsForUser = () => {
    const _per_page = 100;
    const _page = 1;
    dispatch(getAllPendingReviewsForUser({ _per_page, _page }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setPendingPolicyActionsCount({
          pendingReviewsCount: response.data.pendingReviewsCount,
          pendingApprovalsCount: response.data.pendingApprovalsCount,
          pendingAssetApprovalsCount: response.data.pendingAssetApprovalsCount,
          pendingLegalCount: response.data.pendingLegalCount,
          pendingWispApprovalsCount: response.data.pendingWispApprovalsCount,
          pendingTotal:
            response.data.pendingReviewsCount +
            response.data.pendingApprovalsCount +
            response.data.pendingAssetApprovalsCount +
            response.data.pendingLegalCount +
            response.data.pendingWispApprovalsCount,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  return (
    <>
      <NotificationPopup
        open={open}
        onClose={onClose}
        pendingPolicyActionsCount={pendingPolicyActionsCount}
      />
      <IconButton color="inherit" onClick={showNotificationsDialog}>
        <Badge
          badgeContent={pendingPolicyActionsCount.pendingTotal}
          color="error"
        >
          <NotificationsIcon color={darkMode ? "white" : ""} />
        </Badge>
      </IconButton>
    </>
  );
};

const NotificationPopup = ({ open, onClose, pendingPolicyActionsCount }) => {
  const { dark } = colors;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const goToPendingReviewsPage = () => {
    window.location = "/pending-reviews";
  };

  const goToPendingAssetApprovalsPage = () => {
    window.location = "/assets/approvals";
  };

  const goToPendingWispApprovalsPage = () => {
    window.location = "/wisp/approvals";
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Notifications</DialogTitle>
      <DialogContent>
        {pendingPolicyActionsCount.pendingTotal == 0 ? (
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            sx={{
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            You have no notifications at the moment
          </MDTypography>
        ) : (
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              maxHeight: 500,
              marginTop: "1rem",
              "& ul": { padding: 0 },
            }}
            subheader={<li />}
          >
            {pendingPolicyActionsCount.pendingReviewsCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingReviewsPage}
                  dense
                  sx={{ backgroundColor: darkMode ? dark.main : "" }}
                >
                  <ListItemText
                    primary={`Pending Reviews (${pendingPolicyActionsCount.pendingReviewsCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}

            {pendingPolicyActionsCount.pendingApprovalsCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingReviewsPage}
                  dense
                >
                  <ListItemText
                    primary={`Pending Approvals (${pendingPolicyActionsCount.pendingApprovalsCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}
            {pendingPolicyActionsCount.pendingLegalCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingReviewsPage}
                  dense
                >
                  <ListItemText
                    primary={`Pending Legal Reviews (${pendingPolicyActionsCount.pendingLegalCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}
            {pendingPolicyActionsCount.pendingAssetApprovalsCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingAssetApprovalsPage}
                  dense
                >
                  <ListItemText
                    primary={`Pending Asset Approvals (${pendingPolicyActionsCount.pendingAssetApprovalsCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}
            {pendingPolicyActionsCount.pendingWispApprovalsCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingWispApprovalsPage}
                  dense
                >
                  <ListItemText
                    primary={`Pending WISP Approvals (${pendingPolicyActionsCount.pendingWispApprovalsCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onClose}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

const IdlePopup = ({ open, onClose, inactiveLimit, MSPLogoutTime }) => {
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogContent sx={{ padding: "20px", pl: 0, maxWidth: "450px" }}>
        <Card sx={{ boxShadow: 0 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              sx={{ height: "100px", margin: "auto" }}
              image={PostDefaultLogo}
              alt=""
            />
            <CardContent sx={{ pr: 0, pb: 0 }}>
              <MDTypography gutterBottom variant="h4" component="div">
                Are you still here?
              </MDTypography>
              <MDTypography variant="body2" color="secondary">
                This tab has been idle for about{" "}
                {(MSPLogoutTime / 60000).toFixed(1)} minutes and for security
                reasons, you will be auto-logged in {inactiveLimit}
                {inactiveLimit > 1 ? " minutes" : " minute"}
              </MDTypography>
              <MDTypography
                variant="body2"
                textAlign="right"
                sx={{ marginTop: "25px", fontSize: "13px", color: "#38b54a" }}
              >
                *Perform any action to stay logged in
              </MDTypography>
            </CardContent>
          </CardActionArea>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

const DarkModeToggle = (objUser) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const handleDarkMode = () => {
    setDarkMode(dispatch, !darkMode);
    localStorage.setItem("darkMode", !darkMode);
  };

  return (
    <div>
      {darkMode ? (
        <IconButton onClick={handleDarkMode}>
          <LightModeIcon color="secondary" />
        </IconButton>
      ) : (
        <IconButton onClick={handleDarkMode}>
          <DarkModeIcon />
        </IconButton>
      )}
    </div>
  );
};

function DashboardNavbar({ absolute, light, isMini, title }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const dispatching = useDispatch();

  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation();
  const route = location.pathname.split("/").slice(1);
  const routeDictionary = location.state?.routeDictionary ?? {};
  const [isIdle, setIsIdle] = useState(false);
  const [isNewVersion, setIsNewVersion] = useState(false);
  const [logOutTimer, setLogOutTimer] = useState();
  const [inactiveLimit, setInactiveLimit] = useState(5);
  const [MSPLogoutTime, setMSPLogoutTime] = useState(120000);
  const [cookies, setCookie] = useCookies(["isNewVersion"]);
  const [newVersionMessage, setNewVersionMessage] = useState("");
  const [newVersionButtonText, setNewVersionButtonText] = useState("");
  const { objUser } = useSelector((state) => ({
    objUser: state.auth.user.objUser,
  }));
  const { logout } = useAuth0();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const btnOnClickLogout = () => {
    let login_type = localStorage.getItem("login_type");

    localStorage.removeItem("token");
    localStorage.removeItem("is_remember_me");
    localStorage.removeItem("login_type");
    localStorage.clear();

    logout({
      returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
      localOnly: false,
    });
  };

  // function to check for inactivity state
  const inactivityTime = (MSPTime) => {
    let timeId;
    let timeCount;
    window.addEventListener("load", resetTimer);
    document.addEventListener("click", resetTimer);
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keydown", resetTimer);

    function logout() {
      setIsIdle(true);
      let count = 0;
      timeCount = setInterval(() => {
        count++;
        setInactiveLimit(6 - count);
        if (count > 5) {
          clearInterval(timeCount);
          btnOnClickLogout();
        }
      }, 60000);
    }

    function resetTimer() {
      clearTimeout(timeCount);
      if (!logOutTimer) {
        setIsIdle(false);
        clearTimeout(timeId);
        timeId = setTimeout(logout, MSPTime);
        setLogOutTimer(timeId);
      }
    }
  };

  const loadAutoLogoutTime = async () => {
    dispatching(fetchAutoLogoutTimer({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setMSPLogoutTime(parseInt(response.data));
        inactivityTime(response.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  const loadNewVersionInfo = async () => {
    dispatching(getNewVersionInfo())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setNewVersionMessage(
          response.data.message.value ||
            `New Version (${process.env.REACT_APP_APP_VERSION}) released, kindly refresh to get latest features.`
        );
        setNewVersionButtonText(response.data.btnText.value);
        setIsNewVersion(true);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError.response);
      });
  };

  useEffect(() => {
    if (objUser?.user.email_verified_at && isIdle) {
      inactivityTime(MSPLogoutTime);
    }
  }, [isIdle]);

  useEffect(() => {
    let isDarkMode = JSON.parse(localStorage.getItem("darkMode"));
    if (isDarkMode === null || isDarkMode === undefined) {
      localStorage.setItem("darkMode", false);
    } else {
      setDarkMode(dispatch, isDarkMode);
    }
    loadAutoLogoutTime();
    const buttonElement = document.getElementById("countButton");
    buttonElement.click();
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    if (process.env.REACT_APP_APP_VERSION) {
      if (
        !cookies.isNewVersion ||
        cookies.isNewVersion != process.env.REACT_APP_APP_VERSION
      ) {
        loadNewVersionInfo();
      }
    }
  }, [isNewVersion]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title="Manage Podcast sessions"
      />
      <NotificationItem
        icon={<Icon>shopping_cart</Icon>}
        title="Payment successfully completed"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
    cursor: () => {
      return "pointer";
    },
  });

  const refreshForVersion = () => {
    setCookie("isNewVersion", process.env.REACT_APP_APP_VERSION, {
      path: "/",
    });
    window.location.reload();
  };

  const sanitizedNewVersionText = (newVersionMessage) => {
    return DOMPurify.sanitize(newVersionMessage ?? "");
  };

  return (
    <>
      <GamificationSidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) =>
          navbar(theme, { transparentNavbar, absolute, light, darkMode })
        }
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <Breadcrumbs
              icon="home"
              title={
                title.length > 0
                  ? title
                  : routeDictionary[route[route.length - 1]] !== undefined
                  ? routeDictionary[route[route.length - 1]]
                  : route[route.length - 1]
              }
              route={route}
              light={light}
            />
            <IconButton
              sx={navbarDesktopMenu}
              onClick={handleMiniSidenav}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium" sx={iconsStyle}>
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton>
            {isIdle && (
              <IdlePopup
                inactiveLimit={inactiveLimit}
                MSPLogoutTime={MSPLogoutTime}
              />
            )}
            {/* <IdlePopup /> */}
            <button id="countButton" style={{ display: "none" }}></button>
          </MDBox>
          <MDBox color="inherit" sx={{ marginLeft: "auto", display: "flex" }}>
            <NotificationBell darkMode={darkMode} />
            <DarkModeToggle objUser={objUser} />
            {process.env.REACT_APP_BASE_URL !==
              "https://go.compliancerisk.io" && (
              <IconButton
                size="small"
                color="inherit"
                onClick={toggleSidebar}
                sx={navbarDesktopMenu}
              >
                <Icon fontSize="medium" sx={iconsStyle}>
                  bar_chart
                </Icon>
              </IconButton>
            )}
          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox color={light ? "white" : "inherit"}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
                {renderMenu()}
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
        {isNewVersion && (
          <MDBox
            display="flex"
            bgColor="warning"
            p={0.2}
            mx="15px"
            gap={1}
            alignItems="center"
            mb={1}
            mt={1}
            borderRadius={4}
          >
            <ReportProblemIcon
              color="white"
              display="block"
              fontSize="small"
              style={{ marginLeft: "8px" }}
            />
            <MDBox
              sx={{ flexGrow: "1"}}
              bgColor={darkMode ? "dark" : "white"}
              display="flex"
              justifyContent="space-between"
              borderRadius={4}
              pr={2}
            >
              <MDTypography
                fontSize="13px"
                dangerouslySetInnerHTML={{
                  __html: sanitizedNewVersionText(newVersionMessage),
                }}
              ></MDTypography>
              <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                size="small"
                onClick={refreshForVersion}
              >
                {newVersionButtonText ? newVersionButtonText : "Refresh"}
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      </AppBar>
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  title: "",
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  title: PropTypes.string,
};

export default DashboardNavbar;

import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import dataTableData from "views/pages/Dashboard/SuperAdmin/PlatformStats/data/dataTableData";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FifthWallStatsTable from "./components/FifthWallStatsTable/FifthWallStatsTable";
import "./FifthWallStats.css";
import MDTypography from "components/MDTypography";

import { useCookies } from "react-cookie";
import { fetchAllFifthWallStats } from "features/company/superAdminActions";
import { displayExceptionErrorMessage } from "utils/general";

const FifthWallInfo = (props) => {
  const { objUser } = props;
  const [isFetchingFifthWallStats, setIsFetchingFifthWallStats] =
    useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [perPage, setPerPage] = useState(2);
  const [page, setPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  useEffect(() => {
    if (objUser.user.is_super_admin !== 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    fetchFifthWallStats(perPage, page);
  }, []);

  const formatPhone = (inputValue) => {
    let previousValue = inputValue;

    if (inputValue) {
      const numericValue = inputValue.replace(/[^\d]/g, ""); // Remove non-numeric characters

      if (numericValue.length === 10) {
        return `${numericValue.slice(0, 3)}-${numericValue.slice(
          3,
          6
        )}-${numericValue.slice(6)}`;
      } else if (numericValue.length === 11) {
        return `(${numericValue[0]}) ${numericValue.slice(
          1,
          4
        )}-${numericValue.slice(4, 7)}-${numericValue.slice(7)}`;
      } else if (numericValue.length > 11) {
        return previousValue;
      } else {
        return numericValue;
      }
    } else {
      return inputValue;
    }
  };

  const fetchFifthWallStats = () => {
    setIsFetchingFifthWallStats(true);
    dispatch(fetchAllFifthWallStats())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingFifthWallStats(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objFifthWallStatsList = response.data.info;

        for (let index = 0; index < objFifthWallStatsList.length; index++) {
          const element = objFifthWallStatsList[index];
          const objTr = {};
          objTr.user = element.created_by.name;
          objTr.client = element.client.name;
          objTr.mfa =
            element.gtn_mfa === "Yes"
              ? element.gtn_mfa_vendor + " - " + element.gtn_mfa_tool
              : "N/A";
          objTr.security =
            element.gtn_security_awareness === "Yes"
              ? element.gtn_security_awareness_vendor +
                " - " +
                element.gtn_security_awareness_tool
              : "N/A";
          objTr.backup =
            element.gtn_seperate_backup === "Yes"
              ? element.gtn_seperate_backup_vendor +
                " - " +
                element.gtn_seperate_backup_tool
              : "N/A";
          objTr.detection =
            element.gtn_endpoint_managed === "Yes"
              ? element.gtn_endpoint_managed_vendor +
                " - " +
                element.gtn_endpoint_managed_tool
              : "N/A";
          objTr.vulnerability =
            element.gtn_vulnerability === "Yes"
              ? element.gtn_vulnerability_vendor +
                " - " +
                element.gtn_vulnerability_tool
              : "N/A";
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingFifthWallStats(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError)
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDTypography variant="h3">FifthWall Stats</MDTypography>
        <Card sx={{ marginTop: "16px" }}>
          {isFetchingFifthWallStats === false &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <FifthWallStatsTable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              fetchFifthWallStats={fetchFifthWallStats}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              setObjTableData={setObjTableData}
            />
          ) : (
            <Fragment>
              {isFetchingFifthWallStats ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>No FifthWall Stats data</p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default FifthWallInfo;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import DefaultCell from "views/components/TableCells/DefaultCell";
import CustomerNameBtnTr from "./CustomerNameBtnTr";
const CustomerManagementDataTableData = {
  columns: [
    {
      Header: "name",
      accessor: "customerName",
      Cell: ({ value, column, row }) => (
        <CustomerNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "address",
      accessor: "address",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "city",
      accessor: "city",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "state",
      accessor: "state",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "zip",
      accessor: "zip",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "status",
      accessor: "status_label",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default CustomerManagementDataTableData;

import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SaveApiKeysForm from "./components/SaveApiKeysForm";

const ConfigConnectSecure = (props) => {
  const navigate = useNavigate();
  const { objUser } = props;
  const { id } = useParams();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const btnOnClickYesImportCustomers = () => {
    navigate("/connections/connectwise");
  };
  useEffect(() => {
    if (
      objUser.user.role.name != "admin" &&
      objUser.user.role.name != "company_user"
    ) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid
        my={3}
        container
        width="100%"
        spacing={2}
      >
        <SaveApiKeysForm id={id} objUser={objUser} />
      </Grid>
    </DashboardLayout>
  );
};

export default ConfigConnectSecure;

import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

/**
 * Params needed:
 * 1. ObjUser
 * 2. State variable from outer state to store the current PDF Settings
 * 3. Radio stuff to update the settings accordingly
 *
 */

function PDFDownloadSettingsDialog({
  openPdfSettingsDialog,
  onclosePdfSettingsDialog,
  displayPdfSettingsValue,
  setDisplayPdfSettingValue,
  startDownload,
  dataObj,
  isLoadingPDF,
}) {
  const handleDisplaySettingsRadioChange = (event) => {
    if (event.target && event.target.name) {
      setDisplayPdfSettingValue((prev) => {
        return { ...prev, [event.target.name]: event.target.value };
      });
    }
  };

  return (
    <Dialog
      open={openPdfSettingsDialog}
      onClose={onclosePdfSettingsDialog}
      fullWidth={true}
    >
      <DialogTitle sx={{ pb: 0 }}>PDF Download Settings</DialogTitle>
      <DialogContent>
        {/* cover page */}
        <RadioGroup
          name="coverPage"
          value={displayPdfSettingsValue?.coverPage ?? "no"}
          onChange={handleDisplaySettingsRadioChange}
          sx={{ marginTop: "1rem" }}
        >
          <Grid spacing={2} container width="100%">
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                className="emailPreferenceRadioDiv"
                value="yes"
                control={<Radio />}
                label={
                  <div
                    onClick={() =>
                      setDisplayPdfSettingValue((prev) => ({
                        ...prev,
                        coverPage: "yes",
                      }))
                    }
                  >
                    Enable Cover Page
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                className="emailPreferenceRadioDiv"
                value="no"
                control={<Radio />}
                label={
                  <div
                    onClick={() =>
                      setDisplayPdfSettingValue((prev) => ({
                        ...prev,
                        coverPage: "no",
                      }))
                    }
                  >
                    Disable Cover Page
                  </div>
                }
              />
            </Grid>
          </Grid>
        </RadioGroup>

        {/* index */}
        <RadioGroup
          name="indexPage"
          value={displayPdfSettingsValue?.indexPage ?? "no"}
          onChange={handleDisplaySettingsRadioChange}
          sx={{ marginTop: "1rem", display: "none" }}
        >
          <Grid spacing={2} container width="100%">
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                className="emailPreferenceRadioDiv"
                value="yes"
                control={<Radio />}
                label={
                  <div
                    onClick={() =>
                      setDisplayPdfSettingValue((prev) => ({
                        ...prev,
                        indexPage: "yes",
                      }))
                    }
                  >
                    Enable Index
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                className="emailPreferenceRadioDiv"
                value="no"
                control={<Radio />}
                label={
                  <div
                    onClick={() =>
                      setDisplayPdfSettingValue((prev) => ({
                        ...prev,
                        indexPage: "no",
                      }))
                    }
                  >
                    Disable Index
                  </div>
                }
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        {isLoadingPDF ? (
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
        ) : (
          <MDButton
            onClick={() => startDownload(dataObj)}
            type="submit"
            color="success"
            sx={{ padding: "12px 12px" }}
          >
            Start Download
          </MDButton>
        )}
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onclosePdfSettingsDialog}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default PDFDownloadSettingsDialog;

import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import dataTableData from "views/pages/Dashboard/SuperAdmin/PlatformStats/data/dataTableData";
import { useDispatch } from "react-redux";
import { fetchAllPlatformStats } from "features/company/superAdminActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PlatformStatsTable from "./components/PlatformStatsTable/PlatformStatsTable";
import { showMsg } from "utils/general";
import "./PlatformStats.css";
import MDTypography from "components/MDTypography";
import { useCookies } from "react-cookie";
import { displayExceptionErrorMessage } from "utils/general";

const PlatformStats = (props) => {
  const { objUser } = props;
  const [isFetchingPlatformStats, setIsFetchingPlatformStats] = useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [perPage, setPerPage] = useState(2);
  const [page, setPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  useEffect(() => {
    if (objUser.user.is_super_admin !== 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    fetchPlatformStats(perPage, page);
  }, []);

  const formatPhone = (inputValue) => {
    let previousValue = inputValue;

    if (inputValue) {
      const numericValue = inputValue.replace(/[^\d]/g, ""); // Remove non-numeric characters

      if (numericValue.length === 10) {
        return `${numericValue.slice(0, 3)}-${numericValue.slice(
          3,
          6
        )}-${numericValue.slice(6)}`;
      } else if (numericValue.length === 11) {
        return `(${numericValue[0]}) ${numericValue.slice(
          1,
          4
        )}-${numericValue.slice(4, 7)}-${numericValue.slice(7)}`;
      } else if (numericValue.length > 11) {
        return previousValue;
      } else {
        return numericValue;
      }
    } else {
      return inputValue;
    }
  };

  const fetchPlatformStats = (_per_page, _page) => {
    setIsFetchingPlatformStats(true);
    dispatch(fetchAllPlatformStats({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingPlatformStats(false);
        const response = originalPromiseResult;
        const objRows = [];
        const objPlatformStatsList = response.data.platformStatsData;

        for (let index = 0; index < objPlatformStatsList.length; index++) {
          const element = objPlatformStatsList[index];
          const objTr = {};
          objTr.mspCount = element.mspCount;
          objTr.clientsCount = element.clientsCount;
          objTr.totalAccounts = element.totalAccounts;
          objTr.policiesCount = element.policiesCount;
          objTr.authorizedPoliciesCount = element.authorizedPoliciesCount;
          objTr.publishedPoliciesCount = element.publishedPoliciesCount;
          objTr.assessmentEventCount = element.assessmentEventCount;
          objTr.sumOfAssetsSynced = element.sumOfAssetsSynced;
          objTr.assetPolicyCount = element.assetPolicyCount;
          objTr.adoptedPolicyCount = element.adoptedPolicyCount;
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingPlatformStats(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDTypography variant="h3">Platform Stats</MDTypography>
        <Card sx={{ marginTop: "16px" }}>
          {isFetchingPlatformStats === false &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <PlatformStatsTable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              fetchPlatformStats={fetchPlatformStats}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              setObjTableData={setObjTableData}
            />
          ) : (
            <Fragment>
              {isFetchingPlatformStats ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>No platform stats data</p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default PlatformStats;

import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardActionButton from "views/components/DashboardActionButton";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import { showMsg } from "utils/general";
import "./ClientConfig.css";
import MDTypography from "components/MDTypography";
import { Checkbox, Grid } from "@mui/material";
import { useCookies } from "react-cookie";
import CreateNewDialog from "./components/SectionsTable/createNewDialog";
import {
  getSections,
  saveSectionChanges,
} from "features/company/superAdminPolicySectionsActions";
import TextField from "@mui/material/TextField";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import MDButton from "components/MDButton";
import { displayExceptionErrorMessage } from "utils/general";

const Index = (props) => {
  const { objUser } = props;
  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  const [isLoadingSections, setIsLoadingSections] = useState(false);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [selectedIndexToSave, setSelectedIndexToSave] = useState(null);
  const [sections, setSections] = useState([]);
  const [openNewSectionDialog, setOpenNewSectionDialog] = useState(false);
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const btnOnClickNewSection = () => {
    setOpenNewSectionDialog(true);
  };

  const onCloseNewSectionDialog = () => {
    setOpenNewSectionDialog(false);
  };

  const onSubmitNewSectionDialog = (response) => {
    populateSectionData(response);
    setOpenNewSectionDialog(false);
  };

  const btnOnClickSaveSectionChanges = (index) => {
    setIsSavingChanges(true);
    dispatch(saveSectionChanges({ sections }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsSavingChanges(false);
        showMsg("success", "Changes saved successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsSavingChanges(false);
        displayExceptionErrorMessage(rejectedValueOrSerializedError);
      });
  };

  const loadSections = () => {
    setIsLoadingSections(true);
    dispatch(getSections({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingSections(false);
        populateSectionData(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingSections(false);

        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  const populateSectionData = (response) => {
    const objSections = [];
    const objList = response.data.sections;
    setSections(objList);
  };

  const handleChange = (fieldName, index, value) => {
    let updatedSections = [...sections];
    updatedSections[index][fieldName] = value;

    setSelectedIndexToSave(index);
    setSections(updatedSections);
  };

  const handleChecked = (index, include, key) => {
    let updatedSections = [...sections];
    updatedSections[index][key] = include === 1 ? 0 : 1;

    setSelectedIndexToSave(index);
    setSections(updatedSections);
  };

  const handleReodering = (direction, selectedIndex) => {
    const indexToMoveTo =
      direction === "move_up" ? selectedIndex - 1 : selectedIndex + 1;

    if (indexToMoveTo < 0 || indexToMoveTo > sections.length - 1) {
      // Invalid index to move, do nothing
      return;
    }

    setSelectedIndexToSave(indexToMoveTo);
    const updatedSections = [...sections];
    const selectedOrder = updatedSections[selectedIndex].order;

    // Swap sections
    [
      updatedSections[selectedIndex].order,
      updatedSections[indexToMoveTo].order,
    ] = [updatedSections[indexToMoveTo].order, selectedOrder];

    const sortedUpdatedSections = updatedSections.sort(
      (a, b) => a.order - b.order
    );

    setSections(sortedUpdatedSections);
  };

  useEffect(() => {
    if (objUser.user.is_super_admin != 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    loadSections();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <CreateNewDialog
        open={openNewSectionDialog}
        onClose={onCloseNewSectionDialog}
        onSubmit={onSubmitNewSectionDialog}
        objUser={props.objUser}
      />

      <MDBox>
        <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
          <DashboardActionButton
            action={btnOnClickNewSection}
            btnText="Add New Section"
            btnIcon="add"
            textColor="white"
            bgColor="success"
          />
        </MDBox>
        <Grid
          spacing={2}
          container
          width="100%"
          sx={{ alignItems: "flex-start" }}
        >
          {/* md */}

          <Grid item xs={12} lg={10}>
            {isLoadingSections ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            ) : (
              sections.map((section, index) => {
                return (
                  <MDBox
                    className="sectionBox"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "2rem",
                    }}
                    key={section.id}
                  >
                    {/* section content */}
                    <Card
                      className={`sectionContent`}
                      sx={{
                        marginTop: "24px",
                        padding: "20px",
                        width: "80%",
                        border: "2px solid transparent",
                        borderColor:
                          index === selectedIndexToSave
                            ? "rgb(11, 46, 74)"
                            : "transparent",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          backgroundColor:
                            index === selectedIndexToSave
                              ? "rgb(11, 46, 74)"
                              : "#fff",
                          color:
                            index === selectedIndexToSave ? "#fff" : "#000",
                          width: "30px",
                          height: "30px",
                          lineHeight: "30px",
                          borderRadius: "50%",
                          border: "1px solid black",
                          textAlign: "center",
                          verticalAlign: "middle",
                          display: "block",
                        }}
                      >
                        {section.order}
                      </span>
                      {/* title */}
                      <MDBox>
                        <TextField
                          label="Section Title"
                          sx={{
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            width: "70%",
                          }}
                          defaultValue={section.title}
                          onChange={(e) =>
                            handleChange("title", index, e.target.value)
                          }
                          InputLabelProps={{
                            sx: {
                              fontWeight: 700,
                            },
                          }}
                          InputProps={{
                            sx: {
                              fontWeight: 700,
                            },
                          }}
                        />
                      </MDBox>

                      <MDBox display="flex" gap={3}>
                        {/* include in wisp */}
                        <MDBox
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <Checkbox
                            sx={{ marginLeft: 0 }}
                            checked={section.include_in_wisp === 1}
                            onChange={() =>
                              handleChecked(
                                index,
                                section.include_in_wisp,
                                "include_in_wisp"
                              )
                            }
                            icon={
                              <CheckBoxOutlineBlankOutlinedIcon
                                style={{ borderColor: "#0b2e4a" }}
                              />
                            }
                          />
                          <MDTypography
                            onClick={() =>
                              handleChecked(index, section.include_in_wisp)
                            }
                            style={{ fontSize: "0.9rem", cursor: "pointer" }}
                          >
                            Include in WISP
                          </MDTypography>
                        </MDBox>

                        {/* include in ssp */}
                        <MDBox
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <Checkbox
                            sx={{ marginLeft: 0 }}
                            checked={section.include_in_ssp === 1}
                            onChange={() =>
                              handleChecked(
                                index,
                                section.include_in_ssp,
                                "include_in_ssp"
                              )
                            }
                            icon={
                              <CheckBoxOutlineBlankOutlinedIcon
                                style={{ borderColor: "#0b2e4a" }}
                              />
                            }
                          />
                          <MDTypography
                            onClick={() =>
                              handleChecked(index, section.include_in_ssp)
                            }
                            style={{ fontSize: "0.9rem", cursor: "pointer" }}
                          >
                            Include in CCA
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      {/* Reference Material*/}
                      <MDBox></MDBox>
                    </Card>

                    {/* re-ordering */}
                    <MDBox
                      className="sectionReordering"
                      sx={{
                        width: "20%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        gap: "15px",
                        fontSize: "1.5rem",
                        paddingTop: "22px",
                      }}
                    >
                      <ArrowUpwardIcon
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "#50C878",
                          color: "#fff",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          "&:hover": {
                            backgroundColor: "#ccc",
                          },
                          "&:active": {
                            backgroundColor: "#000",
                          },
                        }}
                        onClick={() => handleReodering("move_up", index)}
                      ></ArrowUpwardIcon>
                      <ArrowDownwardIcon
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "#FF7F7F",
                          color: "#fff",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          "&:hover": {
                            backgroundColor: "#ccc",
                          },
                          "&:active": {
                            backgroundColor: "#000",
                          },
                        }}
                        onClick={() => handleReodering("move_down", index)}
                      ></ArrowDownwardIcon>
                    </MDBox>
                  </MDBox>
                );
                {
                  /* end section box */
                }
              })
            )}
          </Grid>
        </Grid>
      </MDBox>

      {isSavingChanges ? (
        <MDButton
          sx={{
            position: "fixed",
            bottom: "3rem",
            right: "3rem",
            padding: "20px",
            backgroundColor: "#fff",
          }}
        >
          <LoadingSpinner
            subClass="text-center"
            color="success"
            size="lg"
            sx={{
              position: "fixed",
              bottom: "3rem",
              right: "3rem",
              padding: "20px",
            }}
          />
        </MDButton>
      ) : (
        <MDButton
          color="info"
          onClick={() => btnOnClickSaveSectionChanges()}
          sx={{
            position: "fixed",
            bottom: "3rem",
            right: "3rem",
            padding: "20px",
          }}
        >
          Save Changes
        </MDButton>
      )}
    </DashboardLayout>
  );
};

export default Index;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ActionBtnTr = (props) => {
  const { row } = props;
  const location = useLocation();
  const routeDictionary = location.state?.routeDictionary ?? {};
  const navigate = useNavigate();

  const btnOnClickConfigureConnector = (data) => {
    routeDictionary[data.id] = props.value;
    let url = "";
    if (data.target === "assets") {
      url = `/settings/ms-graph-assets-connect/${data.id}`;
    } else {
      url = `/settings/ms-graph-email-connect/${data.id}`;
    }
    navigate(url, {
      state: { routeDictionary },
    });
  };

  return (
    <Fragment>
      <MDButton
        color="success"
        onClick={() => btnOnClickConfigureConnector(row.original)}
      >
        <MDTypography
          variant="p"
          fontWeight="medium"
          color="white"
          sx={{ lineHeight: 0, color: "white" }}
        >
          Configure
        </MDTypography>
      </MDButton>
    </Fragment>
  );
};

export default ActionBtnTr;
